import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Modal } from "antd";

import authenticationApi from "apis/authentication";
import { isEmailValid } from "utils/validator";
import EnvolopSVG from "images/SentMail.svg";

const ForgotPasswordModal = ({ isOpen, setIsOpen, setEmailSentModal }) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  const isValid = email => {
    if (!email.trim()) {
      setError("Email can't be blank");
      return false;
    }
    if (!isEmailValid(email)) {
      setError("Invalid email address");
      return false;
    }
    return true;
  };

  const handelResetPassword = async event => {
    event.preventDefault();

    try {
      if (isValid(email)) {
        await authenticationApi.resetPassword({
          user: { email: email.toLowerCase() },
        });
        setIsOpen(false);
        setEmailSentModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal open={isOpen} onCancel={() => setIsOpen(false)} className="w-80" footer={null}>
      <div className="text-xl font-bold pb-5 text-center">Forgot password</div>
      <p className="text-xs pb-3 leading-5 text-center">
        Enter your registered email below to receive password reset instruction
      </p>

      <div className="w-52 h-52 mx-auto">
        <img src={EnvolopSVG} alt="Envolop" className="w-full h-full" />
      </div>

      <div className="w-4/5 mx-auto">
        <Form.Item
          label="Your email:"
          required
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              type: "email",
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setError("");
            }}
            className="w-full text-xs"
          />
        </Form.Item>
        <button
          className="text-white bg-primary py-3 px-12 text-xs rounded-xl font-bold text-center cursor-pointer border-none mx-auto focus:outline-none w-full"
          type="submit"
          onClick={handelResetPassword}
        >
          Send
        </button>
        <div className="flex justify-between items-center py-3">
          <span className="text-xs">Remember password?</span>
          <Link to="/login">
            <span
              className="text-xs cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              Sign In
            </span>
          </Link>
        </div>
      </div>

    </Modal>
  );
};

export default ForgotPasswordModal;
