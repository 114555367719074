// @ts-nocheck
import React from "react";

import Group1 from "images/LandingPageAssets/Team/Group1.svg";
import Group2 from "images/LandingPageAssets/Team/Group2.svg";
import Scott from "images/LandingPageAssets/Team/Scott.jpeg";
import LouiseImage from "images/LandingPageAssets/LouiseImage2.png";
import PaulImage from "images/LandingPageAssets/Team/Paul.jpeg";
import RaynImage from "images/LandingPageAssets/Team/Ryan.jpeg";

const TEAM = [
  {
    name: "Scott Flear",
    title: "CEO & Co-Founder",
    image: Scott,
  },
  {
    name: "Louise Thompson",
    title: "CEO & Co-Founder",
    image: LouiseImage,
  },
  {
    name: "Ryan Libbey ",
    title: "Head Trainer & Co-Founder",
    image: RaynImage,
  },
  {
    name: "PhD Paul Rimmer",
    title: "Head of Nutrition",
    image: PaulImage,
  },
];

function Team() {
  return (
    <section
      className="w-full mx-auto relative"
      style={{ backgroundColor: "#F9F9F9" }}
    >
      <img
        src={Group1}
        alt=""
        className="absolute top-0 left-0 z-10 md:block hidden"
      />
      <img
        src={Group2}
        alt=""
        className="absolute -bottom-2 right-0 z-10 md:block hidden"
      />
      <h3
        className="w-full md:w-3/4 pt-10 md:pt-20 px-2 md:mx-auto font-bold text-4xl md:text-5xl md:pb-12 pb-6 text-center "
        style={{ color: "#172F4F" }}
      >
        Our <u style={{ color: "#ED7870" }}>Leadership Team</u>
      </h3>
      <div className="mx-auto flex flex-wrap justify-center pb-4 md:pb-16">
        {TEAM.map((member, index) => (
          <div
            className="bg-white w-full md:w-1/4 mx-4 mb-4 py-10 flex flex-col justify-center items-center relative z-20"
            key={index}
          >
            <span
              className="rounded-2xl"
              style={{ width: "98px", height: "109px" }}
            >
              <img
                src={member.image}
                alt=""
                className="w-full h-full object-cover rounded-2xl"
              />
            </span>
            <p className="font-bold text-2xl py-4" style={{ color: "#172F4F" }}>
              {member.name}
            </p>
            <p
              className="text-base font-normal pb-5"
              style={{ color: "#405672" }}
            >
              {member.title}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Team;
