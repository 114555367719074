import React from "react";

export const checkIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23.5554C18.382 23.5554 23.5556 18.3818 23.5556 11.9999C23.5556 5.61793 18.382 0.444336 12 0.444336C5.61806 0.444336 0.444458 5.61793 0.444458 11.9999C0.444458 18.3818 5.61806 23.5554 12 23.5554ZM16.2387 6.84317C16.6343 6.47081 17.3093 5.84244 17.9144 6.16826C19.2829 6.90354 17.9266 8.49933 17.1494 9.41375C17.041 9.54124 16.944 9.65548 16.8671 9.7523C16.1998 10.6057 15.5119 11.4385 14.824 12.2713C14.4801 12.6875 14.1363 13.1037 13.795 13.5226C13.4828 13.8991 13.185 14.2901 12.8861 14.6828C12.4274 15.2852 11.9658 15.8914 11.4445 16.4549C11.4178 16.4841 11.3901 16.5147 11.3614 16.5463C10.8644 17.0938 10.0735 17.9652 9.30336 17.9211C8.62844 17.8979 8.04661 17.4091 7.60443 16.9437C6.55714 15.8499 5.13749 14.1277 5.09095 12.5218C5.06767 11.2883 6.18478 11.6607 6.85969 12.1727C7.47976 12.6532 8.00689 13.2061 8.53738 13.7624C8.80334 14.0413 9.07013 14.3211 9.3499 14.5931C9.72227 13.8949 10.3274 13.2898 10.8859 12.7313C11.4924 12.1103 12.063 11.4804 12.6368 10.8471C12.9877 10.4598 13.3398 10.0711 13.7019 9.68248C14.0465 9.31003 14.3761 8.92267 14.7057 8.53532C15.2 7.95452 15.6942 7.37372 16.2387 6.84317ZM6.02183 12.452C6.06837 12.4287 6.09165 12.4287 6.11492 12.4287C5.95201 12.3356 5.83564 12.3123 5.76583 12.3589C5.85892 12.4054 5.92874 12.4287 6.02183 12.452Z"
        fill="#738D9C"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export const emailSvg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.1875 5.03711H2.8125C1.26169 5.03711 0 6.2988 0 7.84961V24.1485C0 25.6993 1.26169 26.961 2.8125 26.961H29.1875C30.7383 26.961 32 25.6993 32 24.1485V7.84961C32 6.2988 30.7383 5.03711 29.1875 5.03711ZM28.8204 6.91211L28.4451 7.22467L17.1159 16.6585C16.4692 17.1969 15.5306 17.1969 14.8841 16.6585L3.55494 7.22467L3.17956 6.91211H28.8204ZM1.875 8.26573L11.1058 15.9522L1.875 22.0955V8.26573ZM29.1875 25.086H2.8125C2.3595 25.086 1.98063 24.763 1.89381 24.3353L12.6091 17.204L13.6844 18.0994C14.3552 18.658 15.1777 18.9373 16.0001 18.9373C16.8224 18.9373 17.6448 18.658 18.3158 18.0994L19.391 17.204L30.1063 24.3352C30.0194 24.763 29.6405 25.086 29.1875 25.086ZM30.125 22.0955L20.8943 15.9522L30.125 8.26573V22.0955Z"
        fill="#73626F"
      />
    </svg>
  );
};
