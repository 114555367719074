/* eslint-disable indent */
// @ts-nocheck
import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// @ts-ignore

import WhiteLogoSvg from "images/LandingPageAssets/whiteLogo.svg";
import IconSVG from "images/Icon.svg";

import { useAuthState } from "contexts/auth";

function NavBar() {
  const location = useLocation();
  const { isLoggedIn } = useAuthState();
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  if (!isLoggedIn && location?.pathname === "/") {
    return <></>;
  }

  return (
    <Fragment>
      <div
        className={
          "hidden w-full pb-10 font-montserrat md:block " +
          ((location?.pathname === "/" || location?.pathname === "/app") &&
          !isLoggedIn
            ? "bg-white"
            : "bg-gray-100")
        }
      >
        <div className="w-3/4 mx-auto">
          <div className="flex items-center justify-between w-full pt-8">
            <Link
              to="/"
              className="no-underline"
              style={{ textDecoration: "none" }}
            >
              <div className="flex justify-center items-center">
                <TurtleMethodLogo />
                <h1 className="ml-2 text-xl leading-5 font-bold align-top">
                  Turtle Method™
                </h1>
              </div>
            </Link>

            <div className="flex items-center justify-end font-semibold leading-6">
              {isLoggedIn ? (
                <Link to="/" className="no-underline">
                  <span className="px-4 py-2 font-semibold no-underline bg-white border rounded-full border-primary text-black cursor-pointer">
                    My Account
                  </span>
                </Link>
              ) : (
                <Link to="/login" className="no-underline">
                  <span className="px-6 py-3 font-semibold no-underline bg-white border rounded-full border-primary text-primary">
                    Sign In
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="w-full">
        <nav className="z-10 flex flex-wrap items-center justify-between w-full py-3 pb-10 mx-auto pin-t md:hidden px-4">
          <div className="flex items-center mr-6 flex-no-shrink">
            <div className=" transform scale-75 h-13">
              <Link to="/" className="no-underline cursor-pointer">
                <div className="flex justify-center items-center">
                  <TurtleMethodLogo />
                  <h1 className="ml-2 text-lg leading-5 font-bold align-top">
                    Turtle Method
                  </h1>
                </div>
              </Link>
            </div>
          </div>

          <div className="block">
            <button
              id="nav-toggle"
              className="flex items-center px-3 py-2 border rounded text-grey border-grey-dark"
              onClick={() => setShowMobileDropdown(!showMobileDropdown)}
            >
              <svg
                className="w-3 h-3 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
        </nav>
      </div>

      {showMobileDropdown && (
        <div
          className="fixed inset-0 z-20 flex flex-col items-center justify-between w-full h-full p-10 text-center text-white"
          style={{ backgroundColor: "#322239" }}
        >
          <Link to="/" className="no-underline">
            <div className="flex justify-center items-center">
              <WhiteLogo />
              <h1 className="ml-2 text-lg leading-5 font-bold align-top">
                Turtle Method
              </h1>
            </div>
          </Link>

          <div className="flex flex-col space-y-5 text-xl">
            {isLoggedIn ? (
              <Link to="/">
                <span
                  onClick={() => {
                    // handleLogout();
                    setShowMobileDropdown(false);
                  }}
                  className="font-semibold no-underline cursor-pointer"
                >
                  My Account
                </span>
              </Link>
            ) : (
              <Link
                to="/login"
                className="font-semibold no-underline cursor-pointer"
                onClick={() => setShowMobileDropdown(false)}
              >
                Sign in
              </Link>
            )}
          </div>

          <div
            className="cursor-pointer"
            onClick={() => setShowMobileDropdown(false)}
          >
            <svg
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="8.76807"
                y="27.8599"
                width="27"
                height="2.2"
                rx="1.1"
                transform="rotate(-45 8.76807 27.8599)"
                fill="white"
              />
              <rect
                x="27.86"
                y="29.4155"
                width="27"
                height="2.2"
                rx="1.1"
                transform="rotate(-135 27.86 29.4155)"
                fill="white"
              />
            </svg>
          </div>
        </div>
      )}
      {/* ======== Mobile ===================*/}
    </Fragment>
  );
}

const TurtleMethodLogo = () => {
  return (
    <div>
      <div className="h-9 w-9 md:h-auto md:w-auto rounded-full flex justify-center items-center">
        <img src={IconSVG} alt="" className="h-4/6 w-4/6 rounded-full" />
      </div>
    </div>
  );
};

const WhiteLogo = () => {
  return (
    <div>
      <div className="h-9 w-9 md:h-auto md:w-auto text-black rounded-full flex justify-center items-center">
        <img src={WhiteLogoSvg} alt="" className="h-full w-full" />
      </div>
    </div>
  );
};

export default NavBar;
