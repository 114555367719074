// @ts-nocheck
import React from "react";
import LouiseBiceps from "images/image-louise-biceps.jpg";
import PlayStoreButton from "images/LandingPageAssets/GooglePlayIcon.png";
import AppStoreButton from "images/LandingPageAssets/AppStoreIcon.png";

const Links = {
  android: "https://play.google.com/store/apps/details?id=com.trtle",
  iOS: "https://apps.apple.com/in/app/nutrition-mindset-coach/id1570131025",
};

function DownloadPage() {
  return (
    <div className="bg-gray-100 min-h-screen w-full font-montserrat">
      <div className="w-full md:w-3/4 mx-auto">
        <DownloadPageUI />
      </div>
    </div>
  );
}

const DownloadPageUI = () => {
  return (
    <div className="w-full rounded md:rounded-3xl">
      <div className="flex w-full">
        <div className="w-1/2 hidden md:block">
          <img
            src={LouiseBiceps}
            alt="Thank you page image"
            className="w-full rounded-l-3xl object-cover h-full"
          />
        </div>

        <div className="w-full bg-white flex-col py-8 md:px-4 md:py-32 items-center justify-between md:w-1/2 md:rounded-r-3xl">
          <div>
            <h1 className="px-6 mb-1 text-2xl font-bold text-center">
              Thank you for subscribing to the Turtle Method™ membership.
            </h1>
            <h3 className="px-6 pt-1 mb-10 font-semibold text-center">
              You can download the app for Android or iOS.
            </h3>
          </div>
          <div className="flex w-4/5 mx-auto  justify-around items-center">
            <a href={Links.android}>
              <img src={PlayStoreButton} alt="" />
            </a>
            <a href={Links.iOS}>
              <img src={AppStoreButton} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
