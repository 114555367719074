export const challengePrices = (priceList = []) => {
  const prices = {};
  priceList.map(price => {
    const membershipType = price?.metadata?.Type || price?.metadata?.type;
    if (membershipType) {
      prices[membershipType] = {
        price: price.unit_amount / 100,
        priceId: price.id,
        productId: price.product,
      };
    }
  });
  return prices;
};

export const challengePricesV2 = (priceList = []) => {
  const prices = {};
  priceList.map(price => {
    const membershipType = price?.metadata?.membershipType.toUpperCase();
    if (membershipType) {
      prices[membershipType] = {
        price: price.unit_amount / 100,
        priceId: price.id,
        productId: price.product,
      };
    }
  });
  return prices;
};

export const challenges = prices => {
  const monthlySub = prices["MONTHLY"];
  // const yearlySub = prices["YEARLY"];

  const CHALLENGES = [
    {
      title: "Monthly plan",
      subheading1:
        "Turtle Method is the nutrition app that gives you your life back",
      subheading2: "Simple. Fast. Smart.",
      priceId: monthlySub.priceId,
      price: monthlySub.price,
      billingCycle: "monthly",
      trialEnd: 14,
    },
  ];
  return CHALLENGES;
};

export const freeTrialChallenges = (prices, trialEnd = 14) => {
  const trialSub = prices["TRIAL"];

  const CHALLENGES = [
    {
      title: "Monthly plan",
      subheading1:
        "Turtle Method is the nutrition app that gives you your life back",
      subheading2: "Simple. Fast. Smart.",
      priceId: trialSub?.priceId,
      price: trialSub?.price,
      billingCycle: "monthly",
      trialEnd: trialEnd,
    },
  ];
  return CHALLENGES;
};

export const transformationChallenge = prices => {
  const monthlySub = prices["MONTHLY"];

  const TRANSFORMATION = [
    {
      // title: "12 Week Strength Plan",
      title: "",
      subheading1: "",
      subheading2: "",
      priceId: monthlySub.priceId,
      price: monthlySub.price,
      billingCycle: "monthly",
      trialEnd: 84,
    },
  ];
  return TRANSFORMATION;
};
