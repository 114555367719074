/* eslint-disable no-console */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { message, Spin } from "antd";

import { PLAN_SECTION_LIST } from "./MembershipPlanCard/constant";
import { prepareMembershipPlanData } from "./MembershipPlanCard/constant";
import MembershipPlanCard from "./MembershipPlanCard/index";
import TurtlePriceTableStoryWeb from "images/LandingPageAssets/turtle_price_table_web.png";
import TurtlePriceTableStoryMobile from "images/LandingPageAssets/turtle_price_table.png";

import { useUserState } from "contexts/user";
import { useAuthState } from "contexts/auth";

import subscriptionApi from "apis/subscriptions";

import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const source = axios.CancelToken.source();

function MembershipPlanDashboard(props) {
  const { user } = useUserState();
  const { isLoggedIn } = useAuthState();
  const [membershipPlanData, setMembershipPlanData] = useState({});
  const [subscribedPlanPriceId, setSubscribePlanPriceId] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [stripeSubscriptionDetails, setStripeSubscriptionDetails] = useState(
    null
  );
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const [selectedPlanPriceId, setSelectedPlanPriceId] = useState("");
  const { setShowSubModal, setPlanTitle, setPriceId } = props;

  useEffect(async () => {
    setLoading(true);
    await fetchPrices();
    if (isLoggedIn) {
      await fetchSubscriptionData();
    }
    setLoading(false);
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (subscriptionId) {
      retriveStripeSubscriptionData(subscriptionId);
    }
    return () => {
      source.cancel();
    };
  }, [subscriptionId]);

  const fetchPrices = async () => {
    try {
      const {
        data: { prices },
      } = await axios.get("/api/v1/prices", { cancelToken: source.token });
      let parsedPricingData = prepareMembershipPlanData(prices);
      setMembershipPlanData(parsedPricingData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSubscriptionData = async () => {
    try {
      const { data } = await axios.get(`/api/v1/users/${user.id}`, {
        cancelToken: source.token,
      });
      const { subscription } = data?.user;
      setSubscribePlanPriceId(subscription?.price?.stripe_price_id);
      setSubscriptionId(subscription?.id);
      setSubscriptionStatus(subscription?.status);
    } catch (error) {
      console.error(error);
    }
  };

  const retriveStripeSubscriptionData = async sub_id => {
    try {
      const { data } = await axios.get(`/api/v1/subscriptions/${sub_id}`);
      setStripeSubscriptionDetails(data.subscription_details);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="py-48">
          <Spin indicator={antIcon} />
        </div>
      </div>
    );
  }

  return (
    <section className="w-full bg-gray-100" style={{ color: "#172F4F" }}>
      <div className="mb-8">
        <img
          src={
            isMobile ? TurtlePriceTableStoryMobile : TurtlePriceTableStoryWeb
          }
        />
      </div>
    </section>
  );
}

export default withRouter(MembershipPlanDashboard);
