import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

import { initializeLogger } from "common/logger";
import { setAuthHeaders, registerIntercepts } from "apis/axios";

import PrivateRoute from "components/Common/PrivateRoute";
import Login from "components/Authentication/Login";
import Navbar from "components/Navbar";

import { useAuthState, useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";

import Welcome from "./WelcomePage";
import ResetPassword from "components/Authentication/ResetPassword";
import PlanCancellation from "./PlanCancellationPage";
import AppLanding from "components/AppLanding/index";
import Masterclass from "components/Masterclass";
import DeleteAccount from "./DeleteAccount";

const Main = props => {
  const [loading, setLoading] = useState(true);
  const { isLoggedIn, isAdmin } = useAuthState();
  const userDispatch = useUserDispatch();
  const authDispatch = useAuthDispatch();

  const setEmail = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const isMobile = queryParams.get("mobile") == "true";
    const redirectEmail = queryParams.get("email");
    if (isMobile && redirectEmail) {
      userDispatch({
        type: "SET_USER",
        payload: { user: { email: redirectEmail }, userId: props.user_id },
      });
    } else {
      userDispatch({
        type: "SET_USER",
        payload: { user: props.user, userId: props.user_id },
      });
    }
  };

  useEffect(() => {
    if (props.user) {
      userDispatch({
        type: "SET_USER",
        payload: { user: props.user, userId: props.user_id },
      });
    } else {
      setEmail();
    }
    initializeLogger();
    registerIntercepts(authDispatch);
    // @ts-ignore
    setAuthHeaders(setLoading);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="py-48">
          <Spin indicator={antIcon} />
        </div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <ToastContainer />
      <Navbar />
      <Switch>
        {!isLoggedIn && <Route exact path="/" component={AppLanding} />}
        <Route exact path="/login" component={Login} />

        {props?.redirectTo && (
          <Redirect
            to={{
              pathname: props?.redirectTo,
            }}
          />
        )}

        <Route exact path="/auth/reset_password" component={ResetPassword} />
        <Route exact path="/delete_account" component={DeleteAccount} />

        <PrivateRoute
          path="/masterclass"
          redirectRoute="/"
          condition={isAdmin}
          component={Masterclass}
        />
        <PrivateRoute
          path="/welcome"
          redirectRoute="/"
          condition={isLoggedIn}
          component={Welcome}
        />

        <PrivateRoute
          path="/plan-cancellation"
          redirectRoute="/"
          condition={isLoggedIn}
          component={PlanCancellation}
        />

        <PrivateRoute
          path="/"
          redirectRoute="/login"
          condition={isLoggedIn}
          component={Welcome}
        />
      </Switch>
    </BrowserRouter>
  );
};

Main.propTypes = {
  user: PropTypes.object,
};

export default Main;
