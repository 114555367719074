import axios from "axios";

const retrieve = ({ email }) =>
  axios.get(`/api/v1/users/retrieve?email=${email}`);

const create = ({ email, priceId, password, passwordConfirmation, trialEnd }) =>
  axios.post("/api/v1/checkouts", {
    checkout: { email, priceId, password, passwordConfirmation, trialEnd },
  });

const checkoutApi = {
  retrieve,
  create,
};

export default checkoutApi;
