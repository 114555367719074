import React from "react";

const Card = ({ challenge, setPriceId, setShowSubModal, setTrialEnd }) => {
  const {
    title,
    subheading1,
    subheading2,
    price,
    priceId,
    trialEnd,
    billingCycle,
  } = challenge;
  const handleCheckout = () => {
    setPriceId(priceId);
    setTrialEnd(trialEnd);
    setShowSubModal(true);
  };

  return (
    <>
      <div
        className="relative py-8 px-8 pb-12 rounded-2xl flex flex-col justify-center items-center"
        style={{ backgroundColor: "rgb(235, 249, 248)" }}
      >
        <div className="text-xl font-bold text-gray-900">{title}</div>
        <div className="w-64 py-3">
          <p className="text-sm">{subheading1}</p>
          <p className="text-sm">{subheading2}</p>
        </div>
        <div className="mt-4 mb-2 text-xl font-bold text-black flex justify-center items-center">
          After {trialEnd} days trial
        </div>
        <p className="text-xs pb-2 font-semibold">
          {`£${price} / ${billingCycle}`}
        </p>
        <div
          className="inline-block w-56 px-5 py-2 font-semibold text-center border rounded-full border-primary cursor-pointer bg-primary text-white"
          onClick={handleCheckout}
        >
          Start
        </div>
        <div className="w-56 pt-3">
          <p className="text-xs font-semibold">Terms & Conditions</p>
          <p className="text-xs">
            Once the trial is complete, the subscription will auto-renew at the
            price of £{price} per month. However, you can cancel this at any
            time
          </p>
        </div>
      </div>
    </>
  );
};

export default Card;
