import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import SubscriptionModal from "components/Payment/SubscriptionModal";
import checkoutApi from "apis/checkout";
import { getStripe } from "utils/initStripe";
import MembershipPlanDashboard from "./MembershipPlanDashboard";

const Hero = () => {
  const [showSubModal, setShowSubModal] = useState(false);
  const [selectedPlanTitle, setPlanTitle] = useState("");
  const [pricedId, setPriceId] = useState("");

  const proceedToCheckout = async ({
    email,
    priceId,
    password,
    passwordConfirmation,
  }) => {
    try {
      const { data } = await checkoutApi.retrieve({
        email: email.toLowerCase(),
      });
      if (!data.allow_checkout) return;
      const stripe = await getStripe();
      const checkoutData = await checkoutApi.create({
        email: email.toLowerCase(),
        priceId,
        password,
        passwordConfirmation,
      });
      const { error: stripeError } = stripe.redirectToCheckout({
        sessionId: checkoutData?.data?.sessionId,
      });
      if (stripeError) alert(stripeError.message);
    } catch (error) {
      console.log("Proceed to checkout err:", error);
    }
  };

  return (
    <>
      <div className="bg-gray-100 min-h-screen w-full font-montserrat">
        <div className="w-full mx-auto">
          {/* <MembershipPlanDashboard
            setPriceId={setPriceId}
            setShowSubModal={() => setShowSubModal(true)}
            setPlanTitle={setPlanTitle}
          /> */}
        </div>
      </div>
      {/* {showSubModal && (
        <SubscriptionModal
          isOpen={showSubModal}
          onClose={() => setShowSubModal(false)}
          proceedToCheckout={proceedToCheckout}
          stripePriceId={pricedId}
          selectedPlanTitle={selectedPlanTitle}
        />
      )} */}
    </>
  );
};

export default withRouter(Hero);
