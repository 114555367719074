import axios from "axios";

const show = ({ id }) => axios.get(`api/v1/users/${id}`);

const exportDailyData = () => {
  const url = `api/v1/users/export_daily_data`;
  return axios.post(url);
};

const exportWeeklyData = () => {
  const url = `api/v1/users/export_weekly_data`;
  return axios.post(url);
};

const deleteAccount = payload => {
  const url = "api/v2/users/delete_user";
  return axios.post(url, payload);
};

const usersApi = {
  show,
  exportDailyData,
  exportWeeklyData,
  deleteAccount,
};

export default usersApi;
