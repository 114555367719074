// @ts-nocheck
import React from "react";

// import LouiseImage2 from "images/LandingPageAssets/LouiseImage2.png";
// import LouiseImage3 from "images/LandingPageAssets/LouiseImage3.png";
// import MaskGroup1 from "images/LandingPageAssets/MaskGroup1.png";
// import CharmTick from "images/LandingPageAssets/charm_tick.png";

import TrustBox from "./TrustPilot";

function ReviewSection() {
  return (
    <>
      <ReviewSection1 />
      {/* <ReviewSection2 /> */}
      {/* <section className="pb-10 md:px-0">
        <div className="w-full md:w-11/12 mx-auto flex pt-5 md:py-10 justify-center flex-col-reverse md:flex-row">
          <div className="relative w-full md:w-1/2 flex items-center justify-center flex-col md:pt-4">
            <img
              src={LouiseImage2}
              alt=""
              className="relative z-20 transform w-10/12 md:w-3/4 mt-10 md:mt-0"
            />
            <img
              src={MaskGroup1}
              alt=""
              className="absolute z-10 top-0 left-0 origin-center"
            />
          </div>
          <div className="w-full md:w-2/5 px-4 md:px-0">
            <h3 className="font-bold text-3xl md:text-4xl pt-8 md:pt-12 pb-5">
              Not a <u className="text-purpleTheme"> Diet Plan</u> in Sight!
            </h3>
            <div className="py-4">
              <div className="flex pb-4">
                <span>
                  <img src={CharmTick} alt="" />
                </span>
                <p className="text-base leading-5 pl-3 font-semibold">
                  Even for first-time trackers, this app makes it easy.
                </p>
              </div>
              <div className="flex pb-4">
                <span>
                  <img src={CharmTick} alt="" />
                </span>
                <p className="text-base leading-5 pl-3 font-semibold">
                  No judgment (all love).
                </p>
              </div>
              <div className="flex pb-4">
                <span>
                  <img src={CharmTick} alt="" />
                </span>
                <p className="text-base leading-5 pl-3 font-semibold">
                  No pressure.
                </p>
              </div>
              <div className="flex pb-4">
                <span>
                  <img src={CharmTick} alt="" />
                </span>
                <p className="text-base leading-5 pl-3 font-semibold">
                  Start for free by joining our waitlist for our next 14 day
                  challenge
                </p>
              </div>
              <div className="flex pb-4">
                <span>
                  <img src={CharmTick} alt="" />
                </span>
                <p className="text-base leading-5 pl-3 font-semibold">
                  Or jump straight in with our membership
                </p>
              </div>
            </div>

            <a href="#contact_us" className="no-underline ">
              <div className="font-medium text-base pb-8 flex flex-col text-center leading-6 cursor-pointer">
                <p className="bg-primary text-white px-2 py-2 rounded-2xl my-6">
                  Start 14-day free trial
                </p>
              </div>
            </a>
          </div>
        </div>
        <div className="w-full md:w-11/12 mx-auto flex pt-5 md:py-16 justify-center items-center flex-col md:flex-row">
          <div className="w-full md:w-1/3 px-4 md:px-0">
            <h3 className="font-bold text-3xl md:text-4xl pt-8 md:pt-12 pb-5">
              A Tool to Help You Achieve a Better{" "}
              <u className="text-purpleTheme">
                Understanding of What You Feed Your Body
              </u>
            </h3>
            <p
              className="text-base font-normal leading-7 pb-5"
              style={{ color: "#405672" }}
            >
              We eat every day. Isn’t it about time we became more curious about
              what we’re putting in our bodies? Using our app and looking into
              your own eating habits judgement-free, we discover obvious easy
              wins to improve our health.
            </p>
            <p
              className="text-base font-normal leading-7 pb-5"
              style={{ color: "#405672" }}
            >
              Having the keys to a super-car is great, but we’ll teach you how
              to drive it too. Our app grants you self-knowledge superpowers.
            </p>
          </div>
          <div className="relative w-full md:w-1/2 flex items-center justify-center flex-col md:pt-4 pb-10">
            <img
              src={LouiseImage3}
              alt=""
              className="relative z-20 transform w-10/12 md:w-3/4 top-10 md:mt-0"
            />
            <img
              src={MaskGroup1}
              alt=""
              className="absolute z-10 top-0 right-0 origin-center"
            />
          </div>
        </div>
      </section> */}
    </>
  );
}

const ReviewSection1 = () => {
  return (
    <section className="md:px-8 relative min-h-screen pt-8 md:pt-0 bg-purpleTheme">
      <div className="flex w-11/12 mx-auto md:pt-24 pb-6 md:pb-10 flex-wrap">
        <div className="w-full text-center mx-auto md:w-3/4 text-3xl md:text-4xl font-bold md:px-10 text-white pb-4">
          You’ll{" "}
          <u style={{ color: "#C7BEFE" }}>Never Need to Join a Slimming Club</u>{" "}
          Ever Again.
        </div>
      </div>
      <div
        className="relative overflow-hidden"
        style={{ scrollbarWidth: "none" }}
      >
        <div className="bg-white py-12">
          <TrustBox />
        </div>
      </div>

      <a href="#contact_us" className="no-underline ">
        <div className="font-medium text-base pb-8 mx-4 flex flex-col items-center text-center leading-6 cursor-pointer">
          <p className="bg-white w-full md:w-2/5 text-primary px-2 py-2 rounded-2xl my-6">
            Join 14 Day Nutrition Masterclass
          </p>
        </div>
      </a>
    </section>
  );
};

export default ReviewSection;
