// Import statements
import "react-quill/dist/quill.snow.css";

import React, { useState, useEffect } from "react";
import { append, any, sortBy, prop, propEq } from "ramda";
import ReactQuill from "react-quill";

// Imported components
import { Loader } from "./Loader";

// API module for masterclass operations
import masterclassApi from "apis/masterclass";

// Initial state
const initialMasterclassState = {
  id: "",
  title: "Turtle One Masterclass",
};

const initialTasksState = [];

// React Quill Modules
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline"],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
};
function Masterclass() {
  // State variables
  const [masterclass, setMasterclass] = useState(initialMasterclassState);
  const [tasks, setTasks] = useState(initialTasksState);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [day_index, setDayIndex] = useState("");
  const [content, setContent] = useState("");
  const [podcastUrl, setPodcastUrl] = useState("");
  const [vimeoShowcaseId, setVimeoShowcaseId] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeMasterclassIndex, setActiveMasterclassIndex] = useState(
    localStorage.getItem("activeMasterclassIndex") ?? 0
  );

  // Fetch masterclasses on component mount
  useEffect(() => {
    setLoading(true);
    fetchMasterclasses();
  }, []);

  useEffect(() => {
    setIsPaneOpen(false);
    setDayIndex("");
    setContent("");
    setPodcastUrl("");
    setVimeoShowcaseId("");
    setLoading(true);
    fetchMasterclasses();
  }, [activeMasterclassIndex]);

  // Fetch masterclasses from the API
  const fetchMasterclasses = async () => {
    try {
      const masterclasses = await masterclassApi.fetchMasterclasses();
      if (masterclasses.length > 0) {
        const firstMasterclass = masterclasses[activeMasterclassIndex]; // TODO: load turtle masterclass or lifesensei
        setMasterclass({
          id: firstMasterclass.id,
          title: firstMasterclass.title,
        });
        setTasks(sortBy(prop("day_index"), firstMasterclass.tasks));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Event handler for clicking "Add Task" button
  const handleAddTaskClick = () => {
    setSelectedTask(null);
    setDayIndex("");
    setContent("");
    setPodcastUrl("");
    setVimeoShowcaseId("");
    setIsPaneOpen(true);
  };

  // Event handler for clicking a task card
  const handleTaskCardClick = task => {
    console.log({ task });
    const { day_index, content, podcast_url, vimeo_showcase_id } = task;
    setSelectedTask(task);
    setDayIndex(day_index);
    setContent(content);
    setPodcastUrl(podcast_url ?? "");
    setVimeoShowcaseId(vimeo_showcase_id ?? "");
    setIsPaneOpen(true);
  };

  // Add validation for day_index and content field
  const isFormValid = () => {
    return day_index !== (undefined || null) && content.trim() !== "";
  };

  // Event handler for saving or updating a task
  const handleSaveTask = () => {
    if (isFormValid()) {
      const newTask = {
        day_index,
        content,
        podcast_url: podcastUrl,
        vimeo_showcase_id: vimeoShowcaseId,
      };

      // Check if the task already exists in the tasks array
      const isTaskDuplicate = any(propEq("day_index", newTask.day_index))(
        tasks
      );
      if (isTaskDuplicate) {
        console.log("Task already exists!");
        return;
      }

      const updatedTasks = append(newTask, tasks);
      setTasks(updatedTasks);
      handleSaveMasterclass(updatedTasks);
      setIsPaneOpen(false);
      setDayIndex("");
      setContent("");
      setPodcastUrl("");
      setVimeoShowcaseId("");
    }
  };

  // Update an existing task
  const handleUpdateTask = async taskID => {
    if (isFormValid()) {
      const updatedTask = {
        day_index,
        content,
        podcast_url: podcastUrl,
        vimeo_showcase_id: vimeoShowcaseId,
      };
      setLoading(true);

      try {
        const updatedTaskResponse = await masterclassApi.updateTask(
          masterclass.id,
          taskID,
          updatedTask
        );

        fetchMasterclasses();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Save or update the masterclass along with the tasks
  const handleSaveMasterclass = async updatedTasks => {
    try {
      const masterclassData = {
        title: masterclass.title,
        tasks_attributes: updatedTasks,
      };

      // Create new
      if (!masterclass.id) {
        await masterclassApi.createMasterclass({
          masterclass: masterclassData,
        });
      } else {
        // Update existing
        await masterclassApi.updateMasterclass(masterclass.id, {
          masterclass: masterclassData,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Event handler for the "Save Task" or "Update Task" button
  const handleTaskBtnClick = () => {
    selectedTask?.id ? handleUpdateTask(selectedTask?.id) : handleSaveTask();
  };

  // Event handler for closing the pane
  const closePane = () => {
    setIsPaneOpen(false);
    setDayIndex("");
    setContent("");
    setPodcastUrl("");
    setVimeoShowcaseId("");
  };

  const handleSwitchMasterclass = () => {
    if (activeMasterclassIndex === 0) {
      setActiveMasterclassIndex(1);
      localStorage.setItem("activeMasterclassIndex", "1");
    } else {
      setActiveMasterclassIndex(0);
      localStorage.setItem("activeMasterclassIndex", "0");
    }
  };

  return (
    <div className="flex flex-col p-10">
      <div className="flex flex-row justify-start mb-4 cursor-pointer">
        <button
          type="button"
          className="inline-flex items-center px-3 py-2 text-sm font-small text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={handleSwitchMasterclass}
        >
          Switch masterclass
        </button>
      </div>
      <div className="flex flex-row justify-between p-4 border mb-4 w-1/4">
        <h2 className="text-4xl font-extrabold">{masterclass.title}</h2>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ul className="w-1/4 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-4">
            {tasks.map((task, index) => (
              <li
                key={index}
                className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600 cursor-pointer"
                onClick={() => handleTaskCardClick(task)}
              >
                <div className="flex justify-between items-center">
                  <a>
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {task.day_index}
                    </h5>
                  </a>
                  <button
                    onClick={() => handleTaskCardClick(task)}
                    className="inline-flex items-center py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    Edit
                    <svg
                      aria-hidden="true"
                      className="w-2 h-2 ml-2 -mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </li>
            ))}
          </ul>

          <button
            type="button"
            className="w-1/4 inline-flex items-center px-3 py-2 text-sm font-small text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={handleAddTaskClick}
          >
            Add Task
          </button>

          {isPaneOpen && (
            <div className="fixed top-0 right-0 z-40 h-screen w-3/5 p-4 overflow-y-auto duration-300 ease-in-out transition-transform translate-x-full bg-white dark:bg-gray-800 sm:translate-x-0">
              <div className="p-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-bold mb-4">
                    {selectedTask ? "Edit Task" : "Add Task"}
                  </h2>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => closePane()}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close</span>
                  </button>
                </div>
                <form>
                  <div className="mb-4">
                    <label htmlFor="day_index">Day Index</label>
                    <input
                      type="text"
                      id="day_index"
                      value={day_index}
                      onChange={e => setDayIndex(e.target.value)}
                      className="border border-gray-300 p-2 w-full"
                      readOnly={selectedTask !== null}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="content">Content</label>
                    <ReactQuill
                      id="content"
                      value={content}
                      onChange={setContent}
                      modules={modules}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="podcast_url">Podcast URL</label>
                    <input
                      type="text"
                      id="podcast_url"
                      value={podcastUrl}
                      onChange={e => setPodcastUrl(e.target.value)}
                      className="border border-gray-300 p-2 w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="vimeo_showcase_id">Vimeo Showcase ID</label>
                    <input
                      type="text"
                      id="vimeoShowcaseId"
                      value={vimeoShowcaseId}
                      onChange={e => setVimeoShowcaseId(e.target.value)}
                      className="border border-gray-300 p-2 w-full"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleTaskBtnClick}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    {selectedTask ? "Update task" : "Save task"}
                  </button>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Masterclass;
