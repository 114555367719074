import axios from "axios";

// type = monthly | yearly
const listPrices = type => axios.get(`/api/v1/subscriptions`);

const productsApi = {
  listPrices,
};

export default productsApi;
