import React from "react";

import TrustBox from "./TrustPilot";

const ReviewSection2 = () => {
  return (
    <section className="py-10 md:py-16" style={{ backgroundColor: "#F9F9F9" }}>
      <h3
        className="w-full md:w-2/3 px-4 md:px-2 md:mx-auto font-bold text-3xl md:text-5xl md:pb-10 pb-6 md:text-center text-left"
        style={{ color: "#172F4F" }}
      >
        We&apos;re{" "}
        <u style={{ color: "#ED7870" }}>
          Not Like Other Food Tracking or Nutrition apps
        </u>{" "}
        Like MFP or Nutracheck
      </h3>

      <p
        className="text-base md:text-xl font-normal text-center pb-6 px-4"
        style={{ color: "#405672" }}
      >
        You get human engagement and support every day backed by science.
      </p>

      <div
        className="relative overflow-hidden"
        style={{ scrollbarWidth: "none" }}
      >
        <div
          className="flex flex-row items-start pt-12 overflow-x-auto flex-nowrap"
          style={{ scrollbarWidth: "none" }}
        >
          <TrustBox />
        </div>
      </div>
      <a href="#contact_us" className="no-underline ">
        <div className="font-medium text-base mx-4 pb-8 flex pt-12 flex-col text-center leading-6 cursor-pointer items-center">
          <p className="bg-primary text-white px-2 py-2 rounded-2xl my-6 w-full md:w-2/5">
            Join 14 Day Nutrition Masterclass
          </p>
        </div>
      </a>
    </section>
  );
};

export default ReviewSection2;
