import axios from "axios";
import { message } from "antd";

const fetchMasterclasses = async () => {
  try {
    const response = await axios.get("/api/v2/mobile/masterclasses");
    return response.data;
  } catch (error) {
    console.error("Error fetching masterclasses:", error);
    message.error(error);
    throw error;
  }
};

const createMasterclass = payload => {
  const url = `api/v2/mobile/masterclasses`;
  return axios.post(url, payload);
};

const updateMasterclass = (id, payload) => {
  axios
    .patch(`/api/v2/mobile/masterclasses/${id}`, payload)
    .then(response => {
      console.log("Masterclass updated successfully:", response.data);
      message.success("Masterclass updated successfully!");
    })
    .catch(error => {
      console.error("Failed to update masterclass:", error);
      message.error(error);
    });
};

const updateTask = async (masterclassId, taskId, updatedTaskData) => {
  try {
    const response = await axios.patch(
      `/api/v2/mobile/masterclasses/${masterclassId}/tasks/${taskId}`,
      {
        task: updatedTaskData,
      }
    );
    message.success("Task updated successfully!");
    return response.data;
  } catch (error) {
    message.error(error);
    // throw error;
  }
};
const masterclassApi = {
  fetchMasterclasses,
  createMasterclass,
  updateMasterclass,
  updateTask,
};

export default masterclassApi;
