// @ts-nocheck
import React, { Fragment } from "react";

import Image1 from "images/LandingPageAssets/Article/Article1.png";
import Image2 from "images/LandingPageAssets/Article/Article2.png";
import Image3 from "images/LandingPageAssets/Article/Article3.png";

function ArticleSection() {
  const ARTICLES = [
    {
      image: Image1,
      title: "Turtle Method vs MyFitnessPal: what you need to know",
      link: "https://turtlemethod.com/turtle-method-vs-myfitnesspal/",
      subtitle:
        "It is a long established fact that a reader will be was distracted by the readable content of a page when that normal distribution ",
    },
    {
      image: Image2,
      title: "The terrifying danger of yo-yo dieting",
      link: "https://turtlemethod.com/dangers-of-yoyo-dieting/",
      subtitle:
        "It is a long established fact that a reader will be was distracted by the readable content of a page when that normal distribution ",
    },
    {
      image: Image3,
      title: "Louise Thomson's 10 fitness tips for summer 2022",
      link: "https://turtlemethod.com/louise-thompson-fitness-tips/",
      subtitle:
        "It is a long established fact that a reader will be was distracted by the readable content of a page when that normal distribution ",
    },
  ];

  return (
    <section className="w-full">
      <h3
        className="w-full md:w-3/4 pt-10 md:pt-20 px-2 md:mx-auto font-bold text-4xl md:text-5xl md:pb-16 pb-6 text-center "
        style={{ color: "#172F4F" }}
      >
        Latest <u style={{ color: "#ED7870" }}>Articles</u>
      </h3>
      <div className="w-11/12 flex justify-evenly flex-wrap mx-auto pb-16">
        {ARTICLES.map((article, index) => (
          <Fragment key={index}>
            <div className="w-3/10 hidden md:block relative">
              <div
                className=" py-2 px-4 absolute top-2 left-2 bg-white rounded-2xl font-bold text-xs"
                style={{ color: "#405672" }}
              >
                Diet Nutrition
              </div>
              <a
                href={article.link}
                className="no-underline"
                key={index}
                rel="noreferrer"
                target="_blank"
              >
                <img src={article.image} alt="" className="w-full" />
                <div className="py-4 flex flex-col">
                  <p
                    className="font-bold text-xl pb-6 pr-20"
                    style={{ color: "#172F4F" }}
                  >
                    {article.title}
                  </p>
                  <p
                    className="font-normal text-base leading-6"
                    style={{ color: "#405672" }}
                  >
                    {article.subtitle}
                  </p>
                </div>
              </a>
            </div>
            <div className="w-full md:hidden py-4 relative">
              <div
                className=" py-2 px-4 absolute top-6 left-2 bg-white rounded-2xl font-bold text-xs"
                style={{ color: "#405672" }}
              >
                Diet Nutrition
              </div>
              <a
                href={article.link}
                className="no-underline"
                key={index}
                rel="noreferrer"
                target="_blank"
              >
                <img src={article.image} alt="" className="w-full" />
                <div className="py-4">
                  <p
                    className="font-bold text-xl pb-6"
                    style={{ color: "#172F4F" }}
                  >
                    {article.title}
                  </p>
                  <p
                    className="font-normal text-base leading-6"
                    style={{ color: "#405672" }}
                  >
                    {article.subtitle}
                  </p>
                </div>
              </a>
            </div>
          </Fragment>
        ))}

        {/* <div className="w-3/10 hidden md:block">
          <img src={Image1} alt="" className="w-full" />
          <div className="py-4">
            <p className="font-bold text-xl pb-6" style={{ color: "#172F4F" }}>
              Turtle Method vs MyFitnessPal:
              <br /> what you need to know
            </p>
            <p
              className="font-normal text-base leading-6"
              style={{ color: "#405672" }}
            >
              It is a long established fact that a reader will be was distracted
              by the readable content of a page when that normal distribution{" "}
            </p>
          </div>
        </div>
        <div className="w-full md:hidden">
          <img src={Image1} alt="" className="w-full" />
          <div className="py-4">
            <p className="font-bold text-xl pb-6" style={{ color: "#172F4F" }}>
              Turtle Method vs MyFitnessPal:
              <br /> what you need to know
            </p>
            <p
              className="font-normal text-base leading-6"
              style={{ color: "#405672" }}
            >
              It is a long established fact that a reader will be was distracted
              by the readable content of a page when that normal distribution{" "}
            </p>
          </div>
        </div> */}

        <div className="w-full md:w-3/10 bg-blue-500"></div>
        <div className="w-full md:w-3/10 bg-blue-500"></div>
      </div>
    </section>
  );
}

export default ArticleSection;
