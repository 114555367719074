// @ts-nocheck
import React, { useState } from "react";
import Tick from "images/LandingPageAssets/tick.svg";
import Cross from "images/LandingPageAssets/charm_cross.svg";
import WhiteTick from "images/LandingPageAssets/WhiteTick.svg";

const FEATURE_COMPARISON = [
  {
    title: "Custom app",
    weightWatchers: Tick,
    mfp: Tick,
    slimmingWorld: Tick,
  },
  {
    title: "Daily podcast",
    weightWatchers: Cross,
    mfp: Cross,
    slimmingWorld: Cross,
  },
  {
    title: "Daily live support every morning (Turtle radio)",
    weightWatchers: Cross,
    mfp: Cross,
    slimmingWorld: Cross,
  },
  {
    title: "Daily group support (from expert)",
    weightWatchers: Cross,
    mfp: Cross,
    slimmingWorld: Cross,
  },
  {
    title: "Weekly expert seminars",
    weightWatchers: Cross,
    mfp: Cross,
    slimmingWorld: Cross,
  },
  {
    title: "1-1 email support with a Nutritionist 5 days a week",
    weightWatchers: Cross,
    mfp: Cross,
    slimmingWorld: Cross,
  },
  {
    title: "Automatic tweaks to calories and macros",
    weightWatchers: Cross,
    mfp: Cross,
    slimmingWorld: Cross,
  },
  {
    title: "Menstrual cycle tracking",
    weightWatchers: Cross,
    mfp: Cross,
    slimmingWorld: Cross,
  },
];

function ComparisonTable() {
  return (
    <>
      <section
        className="py-10 md:py-20 "
        style={{ backgroundColor: "#FFFEFE" }}
      >
        <div className="md:w-8/9 flex mx-auto md:py-12">
          <div className="w-full md:w-2/5 flex flex-col">
            <p
              className="font-medium text-xl md:text-2xl leading-10 text-center md:text-left"
              style={{ color: "#405672" }}
            >
              Turtle Method vs Other Apps{" "}
            </p>
            <p className="font-bold text-3xl md:text-4xl text-center md:text-left px-4 md:px-0">
              How is Our app <u className="text-primary">Different</u> to the
              Rest?
            </p>
          </div>
          <div
            className="md:w-3/5 hidden flex-col px-16 md:flex "
            style={{ color: "#405672" }}
          >
            <span className="font-medium text-xl pb-8">
              Our app isn’t just a tracking tool. We work out the exact <br />{" "}
              calories and macronutrients you need to hit your goals.
            </span>
            <span className="text-base font-normal">
              A little brief comparison table just to see what we offer vs
              market
            </span>
          </div>
        </div>

        <div className="w-8/9 mx-auto md:flex flex-wrap my-0 hidden">
          <div className="w-2/6 invisible "></div>
          <div className="w-1/6 text-white text-center pt-6 pb-8 font-bold text-xl bg-purpleTheme rounded-t-2xl">
            Turtle Method™
          </div>
          <div className="w-1/6 text text-center pt-6 pb-8 font-medium text-xl  border border-tableBorder text-gray-700 rounded-tl-2xl">
            Weight watchers
          </div>
          <div className="w-1/6 text-center pt-6 pb-8 font-medium text-xl  border border-tableBorder text-gray-700">
            MFP
          </div>
          <div className="w-1/6 text-center pt-6 pb-8 font-medium text-xl  border border-tableBorder text-gray-700 rounded-tr-2xl">
            Slimming World
          </div>
          <div className="w-full flex">
            <div className="w-2/6 pl-4 py-5 border text-lg font-medium border-tableBorder rounded-tl-2xl text-gray-700">
              Price per month
            </div>
            <div
              className="w-1/6 flex items-center justify-center text-white font-medium text-xl bg-purpleTheme border"
              style={{ borderColor: "#7F72DD" }}
            >
              9.99
            </div>
            <div className="w-1/6 flex items-center justify-center text-gray-700 font-medium text-xl border border-tableBorder">
              16.95
            </div>
            <div className="w-1/6 flex items-center justify-center text-gray-700 font-medium text-xl border border-tableBorder">
              7.99
            </div>
            <div className="w-1/6 flex items-center justify-center text-gray-700 font-medium text-xl border border-tableBorder">
              20
            </div>
          </div>
          {FEATURE_COMPARISON.map((feature, index) => (
            <div className="w-full flex" key={index}>
              <div className="w-2/6 pl-4 py-5 border text-lg font-medium border-tableBorder text-gray-700">
                {feature.title}
              </div>
              <div
                className="w-1/6 flex items-center justify-center text-white font-medium text-xl bg-purpleTheme border"
                style={{ borderColor: "#7F72DD" }}
              >
                <img src={WhiteTick} alt="" />
              </div>
              <div className="w-1/6 flex items-center justify-center text-gray-700 font-medium text-xl border border-tableBorder">
                <img src={feature.weightWatchers} alt="" />
              </div>
              <div className="w-1/6 flex items-center justify-center text-gray-700 font-medium text-xl border border-tableBorder">
                <img src={feature.mfp} alt="" />
              </div>
              <div className="w-1/6 flex items-center justify-center text-gray-700 font-medium text-xl border border-tableBorder">
                <img src={feature.slimmingWorld} alt="" />
              </div>
            </div>
          ))}
          <div className="w-full flex">
            <div className="w-2/6 pl-4 py-5 border text-lg font-medium border-tableBorder rounded-bl-2xl text-gray-700">
              Daily diary and mental health support
            </div>
            <div
              className="w-1/6 flex items-center justify-center text-white font-medium text-xl bg-purpleTheme border rounded-b-2xl"
              style={{ borderColor: "#7F72DD" }}
            >
              <img src={WhiteTick} alt="" />
            </div>
            <div className="w-1/6 flex items-center justify-center text-gray-700 font-medium text-xl border border-tableBorder rounded-bl-2xl">
              <img src={Cross} alt="" />
            </div>
            <div className="w-1/6 flex items-center justify-center text-gray-700 font-medium text-xl border border-tableBorder">
              <img src={Cross} alt="" />
            </div>
            <div className="w-1/6 flex items-center justify-center text-gray-700 font-medium text-xl border border-tableBorder rounded-br-2xl">
              <img src={Cross} alt="" />
            </div>
          </div>
        </div>
        <MobileViewTable />
      </section>
    </>
  );
}

const MobileViewTable = () => {
  const COMPETITORS = {
    TURTLE: 1,
    WEIGHT_WATCHERS: 2,
    MFP: 3,
    SLIMMING_WORLD: 4,
  };

  const [selected, setSelected] = useState(COMPETITORS.TURTLE);
  return (
    <div className="md:hidden py-6 w-full">
      <div
        className="flex justify-evenly items-center py-4 mb-2"
        style={{ backgroundColor: "#F9F9F9" }}
      >
        <div
          className="py-4 px-2 bg-purpleTheme rounded-2xl text-white text-center text-sm font-bold cursor-pointer"
          onClick={() => setSelected(COMPETITORS.TURTLE)}
        >
          Turtle Method™
        </div>
        <div
          className=" py-4 text-xs font-normal cursor-pointer"
          onClick={() => setSelected(COMPETITORS.WEIGHT_WATCHERS)}
        >
          Weight watchers
        </div>
        <div
          className=" py-4 text-xs font-normal cursor-pointer"
          onClick={() => setSelected(COMPETITORS.MFP)}
        >
          MFP
        </div>
        <div
          className=" py-4 text-xs font-normal cursor-pointer"
          onClick={() => setSelected(COMPETITORS.SLIMMING_WORLD)}
        >
          Slimming World
        </div>
      </div>

      <div className="mx-4 border border-tableBorder rounded-2xl">
        <div className="w-full flex">
          <div className="w-2/3 py-3 pl-3  border-b border-tableBorder rounded-tl-2xl text-gray-700 text-xs font-semibold">
            Price per month
          </div>
          {selected == COMPETITORS.TURTLE && (
            <div className="w-1/3 flex items-center justify-center text-white font-medium text-sm bg-purpleTheme border border-gray-700 rounded-t-2xl">
              9.99
            </div>
          )}
          {selected == COMPETITORS.WEIGHT_WATCHERS && (
            <div className="w-1/3 flex items-center justify-center text-gray-700 font-medium text-sm border rounded-t-2xl">
              16.95
            </div>
          )}
          {selected == COMPETITORS.MFP && (
            <div className="w-1/3 flex items-center justify-center text-gray-700 font-medium text-sm border rounded-t-2xl">
              7.99
            </div>
          )}
          {selected == COMPETITORS.SLIMMING_WORLD && (
            <div className="w-1/3 flex items-center justify-center text-gray-700 font-medium text-sm border rounded-t-2xl">
              20
            </div>
          )}
        </div>

        {FEATURE_COMPARISON.map((feature, index) => (
          <div className="w-full flex " key={index}>
            <div className="w-2/3 py-3 pl-3 rounded-tl-2xl text-gray-700 text-xs font-semibold border-b border-tableBorder">
              {feature.title}
            </div>
            {selected == COMPETITORS.TURTLE && (
              <div className="w-1/3 flex items-center justify-center text-white font-medium text-sm bg-purpleTheme border-b border-l border-gray-700">
                <img src={WhiteTick} alt="" className="transform scale-75" />
              </div>
            )}
            {selected == COMPETITORS.WEIGHT_WATCHERS && (
              <div className="w-1/3 flex items-center justify-center text-gray-700 font-medium text-sm border-b border-l border-tableBorder">
                <img
                  src={feature.weightWatchers}
                  alt=""
                  className="transform scale-75"
                />
              </div>
            )}
            {selected == COMPETITORS.MFP && (
              <div className="w-1/3 flex items-center justify-center text-gray-700 font-medium text-sm border-b border-l border-tableBorder">
                <img src={feature.mfp} alt="" className="transform scale-75" />
              </div>
            )}
            {selected == COMPETITORS.SLIMMING_WORLD && (
              <div className="w-1/3 flex items-center justify-center text-gray-700 font-medium text-sm border-b border-l border-tableBorder">
                <img
                  src={feature.slimmingWorld}
                  alt=""
                  className="transform scale-75"
                />
              </div>
            )}
          </div>
        ))}

        <div className="w-full flex">
          <div className="w-2/3 py-3 pl-3  border-b border-tableBorder rounded-tl-2xl text-gray-700 text-xs font-semibold">
            Daily diary and mental health support
          </div>
          {selected == COMPETITORS.TURTLE && (
            <div className="w-1/3 flex items-center justify-center text-white font-medium text-sm bg-purpleTheme rounded-b-2xl">
              <img src={WhiteTick} alt="" className="transform scale-75" />
            </div>
          )}
          {selected == COMPETITORS.WEIGHT_WATCHERS && (
            <div className="w-1/3 flex items-center justify-center text-gray-700 font-medium text-sm border rounded-b-2xl">
              <img src={Cross} alt="" className="transform scale-75" />
            </div>
          )}
          {selected == COMPETITORS.MFP && (
            <div className="w-1/3 flex items-center justify-center text-gray-700 font-medium text-sm border rounded-b-2xl">
              <img src={Cross} alt="" className="transform scale-75" />
            </div>
          )}
          {selected == COMPETITORS.SLIMMING_WORLD && (
            <div className="w-1/3 flex items-center justify-center text-gray-700 font-medium text-sm border rounded-b-2xl">
              <img src={Cross} alt="" className="transform scale-75" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComparisonTable;
