// @ts-nocheck
import React from "react";

import TrustPilot from "images/TrustPilot.png";
import StarRating from "images/StarRating.png";
import CharmTick from "images/LandingPageAssets/charm_tick.png";
import WhiteTick from "images/LandingPageAssets/WhiteTick.svg";

const PLANS_INFO = {
  MONTHLY: [
    "Daily Podcast",
    `Daily ”Radio”`,
    "Weekly nutrition Q&As",
    "Daily in-depth support",
    "Assess to our nutritionist 1-1 via email",
    "Full app feature",
  ],
  YEARLY: [
    "Daily Podcast",
    `Daily ”Radio”`,
    "Weekly nutrition Q&As",
    "Daily in-depth support",
    "Assess to our nutritionist 1-1 via email",
    "Full app feature",
    `12 week "complete transformation" workout plan [value £79]`,
    "12 week beginners yoga course [value £99]",
  ],
};

function Pricing() {
  return (
    <section
      className="py-6 md:py-12 w-full md:w-11/12 mx-auto"
      style={{ color: "#172F4F" }}
    >
      <h3
        className="w-full md:w-3/4 px-2 md:mx-auto font-bold text-3xl md:text-5xl md:pb-12 pb-6 text-center "
        style={{ color: "#172F4F" }}
      >
        Our <u style={{ color: "#ED7870" }}>Pricing</u>
      </h3>
      <div className="flex flex-wrap justify-center mx-4 md:mx-8">
        {/* ================= */}
        <div
          className="mx-2 md:mx-4 md:w-1/3 w-full border border-solid px-6 py-10 md:py-12 rounded-2xl mb-8"
          style={{ borderColor: "#D9D9D9" }}
        >
          <p
            className="text-lg md:text-xl leading-8 font-medium pb-2"
            style={{ color: "#189877" }}
          >
            Monthly
          </p>
          <h2 className="font-bold text-3xl md:text-4xl">
            £9.99{" "}
            <span className="text-base md:text-xl font-semibold">
              (£119.88 yearly)
            </span>
          </h2>
          <div className="pt-6 pb-4" style={{ color: "#405672" }}>
            {PLANS_INFO.MONTHLY.map((point, index) => (
              <div className="flex md:pb-4" key={index}>
                <span>
                  <img src={CharmTick} alt="" className="transform scale-125" />
                </span>
                <p className="text-base md:text-lg leading-9 pl-3 font-medium">
                  {point}
                </p>
              </div>
            ))}
          </div>
          <div className="pt-4 pb-10 ">
            <div>
              <div className="pb-2">
                <img src={TrustPilot} alt="Trustpilot rating" />
              </div>
              <div className="flex items-center justify-start pb-2">
                <img src={StarRating} alt="" />
                <p className="pl-2 font-medium text-xl">4.8/5</p>
              </div>
              <div className="flex items-center justify-start text-sm">
                <p>Reviews 307</p>
                <p
                  className="h-1 w-1 rounded-full mx-1"
                  style={{ backgroundColor: "#454553" }}
                ></p>
                <p>Excellent</p>
              </div>
            </div>
          </div>
          <button className="w-full py-5 border border-purpleTheme font-bold text-base rounded-2xl text-purpleTheme">
            Signup Now
          </button>
        </div>
        {/* =================== */}
        <div
          className="mx-2 md:mx-4 md:w-1/3 w-full border-2 text-white border-solid px-6 py-12 rounded-2xl bg-purpleTheme mb-8"
          style={{ borderColor: "#685CC1" }}
        >
          <p
            className="text-base md:text-xl leading-8 font-medium pb-2"
            style={{ color: "#FFCC00" }}
          >
            Yearly
          </p>
          <h2 className="font-bold text-3xl md:text-4xl">
            £59{" "}
            <span className="text-base md:text-xl font-semibold">
              {" "}
              (£4.91 per month)
            </span>
          </h2>
          <div className="pt-8 pb-12">
            {PLANS_INFO.YEARLY.map((point, index) => (
              <div className="flex pb-4" key={index}>
                <span>
                  <img src={WhiteTick} alt="" />
                </span>
                <p className="text-base md:text-lg leading-7 pl-3 font-medium">
                  {point}
                </p>
              </div>
            ))}
          </div>
          <button className="w-full py-5 text-purpleTheme bg-white font-bold text-base rounded-2xl">
            Signup Now
          </button>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
