const authReducer = (state, { type, payload }) => {
  switch (type) {
    case "LOGIN": {
      localStorage.setItem("authToken", JSON.stringify(payload.authToken));
      localStorage.setItem("authEmail", JSON.stringify(payload.email));
      return {
        isLoggedIn: true,
        authToken: payload.auth_token,
        authEmail: payload.email,
        isAdmin: payload.is_admin,
      };
    }
    case "LOGOUT": {
      localStorage.setItem("authToken", JSON.stringify(null));
      localStorage.setItem("authEmail", JSON.stringify(null));
      return {
        isLoggedIn: false,
        authToken: null,
        authEmail: null,
        isAdmin: null,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export default authReducer;
