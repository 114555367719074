import React, { useState, useEffect } from "react";
import axios from "axios";

import checkoutApi from "apis/checkout";
import SubscriptionModal from "components/Payment/SubscriptionModal";
import { getStripe } from "utils/initStripe";
import { challengePricesV2, transformationChallenge } from "../constant";

function TransformationCard(props) {
  const [showSubModal, setShowSubModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [challengeDetails, setChallengeDeatils] = useState([]);
  const [priceId, setPriceId] = useState(null);
  const [trialEnd, setTrialEnd] = useState(null);

  useEffect(() => {
    fetchPrices();
  }, []);

  const fetchPrices = async () => {
    try {
      const {
        data: { prices },
      } = await axios.get("/api/v1/prices");
      const subPrices = challengePricesV2(prices);
      setChallengeDeatils(transformationChallenge(subPrices));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const proceedToCheckout = async ({
    email,
    priceId,
    password,
    passwordConfirmation,
  }) => {
    try {
      const { data } = await checkoutApi.retrieve({
        email: email.toLowerCase(),
      });
      if (!data.allow_checkout) return;
      const stripe = await getStripe();
      const checkoutData = await checkoutApi.create({
        email: email.toLowerCase(),
        priceId,
        password,
        passwordConfirmation,
        trialEnd,
      });
      const { error: stripeError } = stripe.redirectToCheckout({
        sessionId: checkoutData?.data?.sessionId,
      });
      if (stripeError) alert(stripeError.message);
    } catch (error) {
      console.log("Proceed to checkout err:", error);
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <div className="">
        <div>
          <div className="container relative px-5 pb-20 mx-auto font-montserrat">
            <h2 className="text-3xl font-bold text-center text-black mb-15 md:text-4xl"></h2>
            <div className="text-center">
              <h1 className="font-semibold text-2xl text-gray-800">
                3 month nutrition & mindset app access
              </h1>
            </div>

            <div className="w-full p-10 mx-auto bg-white rounded-2xl md:w-8/12">
              <div className="flex w-11/12 gap-10 mx-auto text-center justify-center">
                {challengeDetails.map(challenge => {
                  return (
                    <PriceCard
                      key={challenge.title}
                      challenge={challenge}
                      setPriceId={setPriceId}
                      setShowSubModal={setShowSubModal}
                      setTrialEnd={setTrialEnd}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <SubscriptionModal
        isOpen={showSubModal}
        onClose={() => setShowSubModal(false)}
        proceedToCheckout={proceedToCheckout}
        stripePriceId={priceId}
      /> */}
    </>
  );
}

const PriceCard = ({ challenge, setPriceId, setShowSubModal, setTrialEnd }) => {
  const {
    title,
    subheading1,
    subheading2,
    price,
    priceId,
    trialEnd,
    billingCycle,
  } = challenge;
  const handleCheckout = () => {
    setPriceId(priceId);
    setTrialEnd(trialEnd);
    setShowSubModal(true);
  };

  return (
    <>
      <div
        className="relative py-8 px-8 pb-12 rounded-2xl flex flex-col justify-center items-center"
        style={{ backgroundColor: "rgb(235, 249, 248)" }}
      >
        <div className="text-xl font-bold text-gray-900">{title}</div>
        <div className="w-64 py-3">
          <p className="text-sm">{subheading1}</p>
          <p className="text-sm">{subheading2}</p>
        </div>
        <div className="mt-4 mb-2 text-xl font-bold text-black flex justify-center items-center">
          After 84 days trial
        </div>
        <p className="text-xs pb-2 font-semibold">
          {`£${price} / ${billingCycle}`}
        </p>
        <div
          className="inline-block w-56 px-5 py-2 font-semibold text-center border rounded-full border-primary cursor-pointer text-white bg-primary my-6"
          onClick={handleCheckout}
        >
          Start
        </div>
        <div className="w-56 pt-3">
          <p className="text-xs font-semibold">Terms & Conditions</p>
          <p className="text-xs">
            Once the trial is complete, the subscription will auto-renew at the
            price of £{price} per month. However, you can cancel this at any
            time
          </p>
        </div>
      </div>
    </>
  );
};

export default TransformationCard;
