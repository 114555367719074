// @ts-nocheck
import React, { useState } from "react";

import PlusIcon from "images/LandingPageAssets/PlusIcon.svg";
import NegIcon from "images/LandingPageAssets/NegIcon";

const FAQ = [
  {
    position: 1,
    question: "How much is the app per month?",
    answer: ["It's £9.99 pm, but you can cancel at any time without charges."],
  },
  {
    position: 2,
    question: "What's the benefit of paying for the year upfront?",
    answer: [
      "It works out as just £4.91 pm if you pay for the year upfront. You also get our 12-week complete transformation training plan included for free (this costs £59 as a one-off cost)",
    ],
  },
  {
    position: 3,
    question: "How is this app any different from the free food tracking apps?",
    answer: [
      "Our app has a food tracker as one element. Just tracking your food isn't enough. What should your calories and macros be? How should you change them over time? Our app does this for you automatically. We track your stress, menstrual cycle, sleep, steps, weight, measurements, and general mental health. This data (and we collect it uniquely and efficiently) helps us build a picture of your total health, and we make changes based on YOU, nobody else.",
      "We also have an auto adjuster feature which means the app will add/remove calories to your days depending on if you went over/under your goals on previous days. This gives you total flexibility to eat more/less on different says, and that's how life is!",
      "As a subscriber, you also get access to our private support group with weekly nutrition Q&A with Dr Paul Rimmer, daily micro podcast and our daily radio show with members. There's always support available for you to jump into. Unlike other apps, we offer human support every day of the week. You can email our nutritionist at any time to look at your data and answer any queries too.",
    ],
  },
  {
    position: 4,
    question: `What is "Turtle Smart Mind"?`,
    answer: [
      "Because we uniquely collect data and help you become your own scientist, we've developed a machine-learning algorithm which learns from your data over time. As it 'learns', it will change your nutrition at your weekly check-in if it thinks it's needed.",
      "Many factors go into the algorithm, and it's been built to ensure it learns from you, not generic models or equations.",
      "This makes our app unique amongst the rest. MFP, for example, just lets you track your food. It doesn't change anything for you or offer predictive insights",
    ],
  },
  {
    position: 5,
    question: "What's Turtle Radio?",
    answer: [
      "Every day from 7.30 - 8.30am, we open an audio-only chat room we call Turtle Radio. You can just listen in on your way to work or join as a speaker with our coaches. We chat with members about life, nutrition, struggles, daily plans, etc. Our members love this feature because they get the mindset kick at the start of the day",
      "You'll learn a lot by listening to other members' questions and interactions. You don't need to participate, and replays are always available.",
      "But give it a go, replace the usual radio station with Turtle Radio and see how it changes your day.",
    ],
  },
  {
    position: 6,
    question: "Do I have to weigh myself daily?",
    answer: [
      "Daily weigh-ins are preferable as they show you how up/down weight can be. We take a weekly average for this and compare weekly averages over time. However, you don't need to weigh yourself. It may take longer for the algorithm to learn about you with fewer data, but it's possible.",
      "You can take measurements instead if you wish. We have multiple options for you to track progress, but inside the community, we explain all of this to understand which method best suits you",
    ],
  },
  {
    position: 7,
    question: "Does your app sync with fitness trackers?",
    answer: [
      "We sync with Apple health (with loads of 3rd party apps sync, too) and Fitbit. We have Garmin, Samsung, and Google fit syncs coming very soon.",
    ],
  },
  {
    position: 8,
    question: `What's a weekly "check-in"?`,
    answer: [
      "After you complete 7 days, you'll be prompted to answer a few questions, and we will complete a review of your week. We crunch your numbers to see if any changes need to be made to your calories and macros.",
    ],
  },
  {
    position: 9,
    question: "I don't have much time, is this for me?",
    answer: [
      "Once we help you use the app, you'll only need to use it for 5 minutes a day. Once you've built up your foods, recipes and syncs, you will only need to do a few taps a day. Think about that, just 5 minutes a day for a complete health change!",
    ],
  },
  {
    position: 10,
    question:
      "I'm a total beginner to this whole thing. I don't know anything about macros!",
    answer: [
      "We help you take this one day at a time. We've helped countless people use the app who had never heard of macros before. It's not a diet, you eat the foods you like, and we promote you eat your fave snacks too. ",
      "What matters is trying to hit your daily targets. Once you use the app (which has a barcode scanner), you'll realise how easy it is. It's like playing a game of Tetris! ",
      "We have daily support on hand and 1-1 email channel too. You're never left alone.",
    ],
  },
  {
    position: 11,
    question: "What's the daily diary?",
    answer: [
      "We've worked with world-class psychotherapists to build a morning and evening daily diary feature. This helps you plan your day, and track your mood, stress, sleep and daily tasks. By doing the morning and evening daily diary, you'll feel grounded in the present moment, and it helps enormously with stress and mental health.",
    ],
  },
  {
    position: 12,
    question: "I have some questions about the app; who should I email?",
    answer: ["Please email scott@turtlemethod.com "],
  },
];

function FaqSection() {
  const [selected, setSelected] = useState(null);
  return (
    <section
      className="w-full mx-auto relative bg-white"
      style={{ backgroundColor: "#ECECEC" }}
    >
      <h3 className="w-full md:w-3/4 pt-10 md:pt-20 px-2 md:mx-auto font-bold text-3xl md:text-5xl md:pb-12 pb-6 text-center ">
        Frequently Asked <u style={{ color: "#ED7870" }}>Questions</u>
      </h3>
      <div className=" w-full md:w-3/5 md:mx-auto  pb-16">
        {FAQ.map((faq, index) => (
          <div className="px-4 md:px-0 w-full border-t border-b" key={index}>
            <div className="flex justify-between items-center py-6">
              <p
                className="text-base md:text-xl w-4/5 font-medium"
                style={{ color: "#405672" }}
              >
                {faq.question}
              </p>
              {faq.position == selected && (
                <div className="w-1/5 flex justify-end">
                  <span
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => setSelected(null)}
                  >
                    <img src={NegIcon} alt="-" className="" />
                  </span>
                </div>
              )}
              {faq.position !== selected && (
                <div className="w-1/5 flex justify-end">
                  <span
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => setSelected(faq.position)}
                  >
                    <img src={PlusIcon} alt="+" className="w-full h-full" />
                  </span>
                </div>
              )}
            </div>
            {selected == faq.position && (
              <div className="pb-4 w-11/12">
                {faq.answer.map((answer, index) => (
                  <p
                    className="pb-2 text-base font-normal leading-7"
                    style={{ color: "#172F4F" }}
                    key={index}
                  >
                    {answer}
                  </p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default FaqSection;
