/* eslint-disable indent */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/en-gb";

import RightArrowImage from "images/RightArrowImage.png";
import LouiseBiceps from "images/image-louise-biceps.jpg";

import usersApi from "apis/users";
import subscriptionApi from "apis/subscriptions";

import { useUserState } from "contexts/user";
import { useAuthState } from "contexts/auth";

import { useAuthDispatch } from "contexts/auth";
import authenticationApi from "apis/authentication";
import { resetAuthTokens } from "apis/axios";

import { LoadingOutlined } from '@ant-design/icons';
import { message, Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

moment.updateLocale("en-gb");

function PlanCancellation(props) {
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const { isLoggedIn } = useAuthState();

  const { user } = useUserState();
  const authDispatch = useAuthDispatch();

  const fetchData = async () => {
    try {
      const { data } = await usersApi.show({ id: user.id });
      const customerId = data.user.stripe_customer_id;
      if (customerId !== "cus_random_id") {
        setStripeCustomerId(customerId);
      }
      setSubscriptionInfo({
        ...data.user.subscription,
        membershipLabel: data.user.membership_label,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchData();
    }
  }, []);

  if (!subscriptionInfo || isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="py-48">
          <Spin indicator={antIcon} />
        </div>
      </div>
    );
  }

  // const BaremetricsCancellationInsightScript = ({ stripeCustomerId }) => {
  //   console.log("baremetrics: ", { stripeCustomerId })
  //   return !(function () {
  //     if (window.barecancel && window.barecancel.created)
  //       window.console &&
  //         console.error &&
  //         console.error("Barecancel snippet included twice.");
  //     else {
  //       window.barecancel = { created: !0 };
  //       var a = document.createElement("script");
  //       (a.src =
  //         "https://baremetrics-barecancel.baremetrics.com/js/application.js"),
  //         (a.async = !0);
  //       var b = document.getElementsByTagName("script")[0];
  //       b.parentNode.insertBefore(a, b),
  //         (window.barecancel.params = {
  //           access_token_id: "b76f4171-6afa-48bd-8917-93f7a7c57be8",
  //           customer_oid: stripeCustomerId,
  //           callback_send: function (data) {
  //             handleCancelSubscription(subscriptionId);
  //             console.info("data: ", data);
  //           },
  //           callback_error: function (error) {
  //             console.info("error ", error);
  //           },
  //         });
  //     }
  //   })();
  // };

  const handleCancelletionClick = () => {
    if (!stripeCustomerId) {
      message.error("Subscription not found! Please email Trtle support team for subscription regarding issues!");
    } else {
      handleCancelSubscription(subscriptionInfo.id);
    }
  };

  const handleCancelSubscription = async id => {
    try {
      setLoading(true);
      await authenticationApi.logout();
      await subscriptionApi.cancelSubscription(id);
      authDispatch({ type: "LOGOUT" });
      resetAuthTokens();
      props.history.push("/");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const {
    current_period_end,
    current_period_start,
    price,
    metadata,
    id: subscriptionId,
    membershipLabel,
  } = subscriptionInfo;

  return (
    <div className="bg-gray-100 min-h-screen w-full font-montserrat">
      {/* {stripeCustomerId && (
        <BaremetricsCancellationInsightScript
          stripeCustomerId={stripeCustomerId}
        />
      )} */}
      <div className="w-full md:w-3/4 mx-auto">
        <PlanCancellationPage
          endDate={moment(current_period_end).format("LL")}
          startDate={moment(current_period_start).format("LL")}
          price={price.amount}
          planTitle={
            membershipLabel || metadata?.plan?.metadata?.membershipTitle
          }
          handleCancelletionClick={handleCancelletionClick}
        />
      </div>
    </div>
  );
}

const PlanCancellationPage = ({ endDate, startDate, price, planTitle, handleCancelletionClick }) => {
  const isTrial =
    planTitle.toLowerCase().includes("masterclass") ||
    planTitle.toLowerCase().includes("trial"); // Add keywords for trial plans later

  return (
    <div
      className="w-full mb-10 rounded md:rounded-l-3xl"
      style={{
        color: "#094043",
      }}
    >
      <div className="flex w-full">
        <div className="w-1/2 hidden md:block pb-5">
          <img
            src={LouiseBiceps}
            alt="Thank you page image"
            className="w-full rounded-l-3xl h-full object-cover"
          />
        </div>
        <div className="md:w-1/2 w-full bg-white flex justify-center items-center md:rounded-r-3xl">
          <div className="w-5/6 mx-auto my-20">
            <div className="text-3xl font-bold mb-8 text-center md:text-left">
              {isTrial ? "Looking to cancel?" : "Cancellation"}
            </div>

            {isTrial ? (
              <p className="font-medium text-sm mb-4 leading-snug text-center md:text-left">
                You have a <strong>{planTitle}</strong> that’s active from{" "}
                <strong>{startDate}</strong> to <strong>{endDate}</strong>.
              </p>
            ) : (
              <p className="font-medium text-sm mb-4 leading-snug text-center md:text-left">
                You are cancelling your <strong>{planTitle}</strong> that’s
                active from <strong>{startDate}</strong> to{" "}
                <strong>{endDate}</strong>.
              </p>
            )}

            {isTrial ? (
              <p className="text-sm mb-4 leading-snug font-medium text-center md:text-left">
                No need to cancel, this trial auto cancels at your end date. No
                payment methods have been taken so no payment can be taken from
                you. <br />
                <br />
                If you wish to continue with Turtle please check your app at the
                final few days for how to do so
              </p>
            ) : (
              <p className="text-sm mb-4 leading-snug font-medium text-center md:text-left">
                Note: cancelling means you will retain access until the end date
                above.
              </p>
            )}

            {!isTrial && (
              <div className="text-xl font-bold pb-6 text-center md:text-left">
                Do you want to cancel now?
              </div>
            )}

            <div>
              <div className="flex flex-col-reverse md:flex-row items-center py-8">
                <Link to="/welcome" className="no-underline">
                  <div
                    className="py-4 px-4 text-primary rounded-2xl font-bold text-center cursor-pointer text-xs flex items-center justify-center mr-4 w-48 md:w-auto"
                    // style={{
                    //   color: "#19A198",
                    //   backgroundColor: `#F1F2F4`,
                    // }}
                  >
                    <img
                      src={RightArrowImage}
                      alt=""
                      className="h-4 w-4 mr-2 text-purpleTheme"
                    />
                    Go back
                  </div>
                </Link>
                {!isTrial && (
                  <div
                    className="text-white py-4 px-6 rounded-2xl font-bold text-center cursor-pointer text-xs flex items-center justify-center mr-3 mb-2 md:mb-0 w-48 md:w-auto bg-primary"
                    id="barecancel-trigger"
                    onClick={handleCancelletionClick}
                  >
                    Confirm Cancellation
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PlanCancellation);
