import axios from "axios";

const getSubscriptionUrl = id => {
  return `/api/v1/subscriptions/${id}`;
};

const cancelSubscription = id => axios.delete(getSubscriptionUrl(id));

const switchPlan = (id, payload) => axios.put(getSubscriptionUrl(id), payload);

const subscriptionApi = { cancelSubscription, switchPlan };

export default subscriptionApi;
