// @ts-nocheck
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { setAuthHeaders } from "apis/axios";
import LouiseBiceps from "images/image-louise-biceps.jpg";
import { message, Input, Spin } from "antd";

import authenticationApi from "apis/authentication";
import { isEmailValid } from "utils/validator";
import { useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";

import ForgotPassword from "./ForgotPassword";
import SendEmailConfirmation from "./sendEmailConfirmation";

import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const INITIALIZE_ERRORS = {
  email: "",
  password: "",
};

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setError] = useState(INITIALIZE_ERRORS);
  const [isForgotPasswordModalOpen, setForgotPasswordModal] = useState(false);
  const [isEmailSentModalOpen, setEmailSentModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();

  const isValid = () => {
    let emailError = "";
    let passwordError = "";

    if (!email.trim()) {
      emailError = "Email can't be blank.";
    } else {
      if (!isEmailValid(email)) {
        emailError = "Invalid email address";
      }
    }
    if (!password) {
      passwordError = "Password can't be blank.";
    } else {
      if (password.length < 6) {
        passwordError = "Password needs to be at least 6 characters long";
      }
    }
    if (!emailError && !passwordError) {
      return true;
    } else {
      setError({ ...errors, email: emailError, password: passwordError });
      return false;
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      if (!isValid()) {
        return;
      }
      setLoading(true);
      const {
        data: { authToken, user, is_admin },
      } = await authenticationApi.login({
        user: { email: email.toLowerCase(), password },
      });
      authDispatch({
        type: "LOGIN",
        payload: { authToken, email: email.toLowerCase(), is_admin },
      });
      userDispatch({
        type: "SET_USER",
        payload: { user },
      });
      setAuthHeaders();
      history.push("/welcome");
      message.success("Logged in successfully.");
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="py-48">
          <Spin indicator={antIcon} />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen w-full font-montserrat">
      <div className="w-full md:w-3/4 mx-auto">
        <div
          className="w-full rounded md:rounded-l-3xl"
          style={{
            color: "#094043",
          }}
        >
          <div className="flex w-full">
            <div className="w-1/2 hidden md:block">
              <img
                src={LouiseBiceps}
                alt="Thank you page image"
                className="w-full rounded-l-3xl object-cover h-full"
              />
            </div>

            <form
              className="w-full bg-white flex items-center justify-between md:w-1/2 md:rounded-r-3xl"
              onSubmit={handleSubmit}
            >
              <div className="w-full py-4 md:px-4 md:py-12">
                <div className="text-3xl w-5/6 md:w-4/5 font-bold mx-auto text-center py-8">
                  Sign in
                </div>

                <div className="md:w-7/12 w-4/5 mx-auto ">
                  <Input
                    addonBefore="Your email:"
                    type="email"
                    // addonClassName="test-xs pb-1 pl-1"
                    className="w-full rounded-full mb-2"
                    error={errors.email}
                    placeholder="oliver@example.com"
                    id="user_email"
                    onChange={e => {
                      setEmail(e.target.value);
                      setError(INITIALIZE_ERRORS);
                    }}
                    value={email}
                  />

                  <Input.Password
                    addonBefore="Password:"
                    // addonClassName="test-xs pb-1 pl-2"
                    name="password"
                    error={errors.password}
                    className="w-full rounded-full bg-white mb-2"
                    placeholder="******"
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value);
                      setError(INITIALIZE_ERRORS);
                    }}
                  />

                  <div
                    className="text-center font-medium text-xs py-3 cursor-pointer"
                    onClick={() => setForgotPasswordModal(true)}
                  >
                    Forgot password?
                  </div>

                  <button
                    className="bg-primary text-white w-full py-3 my-2 rounded-xl font-bold text-center cursor-pointer focus:outline-none mx-auto"
                    type="submit"
                  >
                    Sign in
                  </button>

                  <hr className="w-4/5 my-5 mx-auto" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ForgotPassword
        isOpen={isForgotPasswordModalOpen}
        setIsOpen={setForgotPasswordModal}
        setEmailSentModal={setEmailSentModal}
      />
      <SendEmailConfirmation
        isOpen={isEmailSentModalOpen}
        setIsOpen={setEmailSentModal}
      />
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default Login;
