// @ts-nocheck
import React from "react";

import FiveStar from "images/LandingPageAssets/FiveStar.svg";

function PodcastReview() {
  const PODCAST_REVIEWS = [
    {
      name: "Flo Lily",
      boldText: "Honest & uplifting!",
      texts: [
        "I have LOVED the daily podcasts. Not only is the content beneficial, they have also got me out walking every lunchtime listening to them as I work from home. It’s like having a helpful friend in your ear. I have nothing but good to say about my experience with Turtle Method and all the amazing things they offer. Thanks Scott & the team!",
      ],
      time: "30/03/2022",
    },
    {
      name: "Gharwood",
      boldText: "5 stars isn’t enough!",
      texts: [
        "Spent years stuck in a rut with weight gain after giving up dancing as a career and had accepted my “natural body shape” was just “bigger”. Trtle help you remove all bias toward yourself, they encourage and empower - not beat down and insult. You will feel brighter, more positive, and then the weight starts to drop as a byproduct. Invest in yourself, this takes 15 minutes a day and it’s a game changer. You won’t regret it.",
      ],
      time: "22/03/2022",
    },
    {
      name: "Hannah L G",
      boldText: "Great podcast",
      texts: [
        "Great straight talking podcast. The messages are conveyed simply and Scott gets right to the key points to help you figure out the best steps to take. Love the input of research and real life examples. It’s like having a daily pep talk to get you ready for the day ahead!",
      ],
      time: "24/03/2022",
    },
    {
      name: "Carrie981",
      boldText: "A great start to the day",
      texts: [
        "These daily podcasts are absolutely fantastic and a great way to start your day. Scott is really informative on subjects that are usually made so complicated - it’s a breath of fresh air! Learnt so much from doing the 14 day mindset challenge and it has given me the kick-start I needed to change my lifestyle for a happy me.",
      ],
      time: "25/03/2022",
    },
    {
      name: "SophieJas93",
      boldText: "Amazing!",
      texts: [
        "This was such a great way to get back into working on myself! Sets you up for the day and really focuses on mindfulness and looking inwards! Really enjoyed it!",
      ],
      time: "30/03/2022",
    },
    {
      name: "beautruce",
      boldText: "Amazing, mindset game changer!",
      texts: [
        "Scotts podcasts make my mornings! His honest advice & fun way of teaching was an absolute game changer for me! Months of struggling & 2 weeks with scott & i am in a better place mentally & physically! Cannot wait to see where i am after my year with him!",
      ],
      time: "22/03/2022",
    },

    {
      name: "JeniLowe",
      boldText: "Easy to digest!",
      texts: [
        "Short and to the point, Scott successfully manages to cover key areas that help with the mindset of how we can approach a healthier lifestyle. Whether this is how we think about food, about the diet industry or about how we view ourselves, Scott manages to give some thought provoking ideas! An easy podcast to digest and one I enjoyed starting my day with!",
      ],
      time: "22/03/2022",
    },
    {
      name: "amy smallman",
      boldText: "You won’t look back!",
      texts: [
        "What a fabulous two weeks! I have learnt so much from these podcasts, will re listen again and again! Delivered really well and felt like a friend was talking to us the whole way through! Thanks Scott!",
      ],
      time: "22/03/2022",
    },
    {
      name: "graham mck",
      boldText: "Very informative",
      texts: [
        "Scott is lighthearted!! puts a smile on your face in the morning, I’ve learned a lot listening to these podcasts a really great way to start your day especially if health and fitness is your goal.",
      ],
      time: "22/03/2022",
    },
    {
      name: "Keriss.Scotland",
      boldText: "Mindset trial",
      texts: [
        "The whole point of me joining was to get help with my food, but after these two weeks I think the most important thing about this trial was the daily podcasts. It put me in such a great routine to start the day along with trtle radio and the information has been crazy! Scott is very laidback in his manner and comes across as knowledgable, friendly and motivating.",
      ],
      time: "22/03/2022",
    },
    {
      name: "Bonney C",
      boldText: "Amazing Podcasts",
      texts: [
        "These podcasts have really changed my mindset towards eating and loosing weight in such a short space of time! I found them very motivating every morning and quickly welcomed them into my daily routine. If you are looking for something to change your mindset as well as your lifestyle, these are for you!!",
      ],
      time: "22/03/2022",
    },
    {
      name: "JeniLowe",
      boldText: "Easy to digest!",
      texts: [
        "Short and to the point, Scott successfully manages to cover key areas that help with the mindset of how we can approach a healthier lifestyle. Whether this is how we think about food, about the diet industry or about how we view ourselves, Scott manages to give some thought provoking ideas! An easy podcast to digest and one I enjoyed starting my day with!",
      ],
      time: "22/03/2022",
    },
    {
      name: "Keriss.Scotland",
      boldText: "Mindset trial",
      texts: [
        "The whole point of me joining was to get help with my food, but after these two weeks I think the most important thing about this trial was the daily podcasts. It put me in such a great routine to start the day along with trtle radio and the information has been crazy! Scott is very laidback in his manner and comes across as knowledgable, friendly and motivating. The whole trial has been so positive that I have signed up for the annual",
      ],
      time: "22/03/2022",
    },
  ];

  return (
    <section className="py-6 md:py-10" style={{ backgroundColor: "#F9F9F9" }}>
      <h3
        className="w-full md:w-3/4 px-2 md:mx-auto font-bold text-3xl md:text-5xl md:pb-4 pb-4 text-center "
        style={{ color: "#172F4F" }}
      >
        Daily 15-min <u style={{ color: "#ED7870" }}>Podcasts</u>
      </h3>
      <p className="w-full md:w-3/4 px-2 md:mx-auto font-bold text-base md:text-2xl md:pb-6 text-center leading-7">
        (That <u style={{ color: "#ED7870" }}> break down nutrition myths</u> in
        easy to understand bite-sized chunks!)
      </p>
      <div
        className="relative overflow-hidden"
        style={{ scrollbarWidth: "none" }}
      >
        <div
          className="flex flex-row items-start md:py-20 py-12 overflow-x-auto flex-nowrap"
          style={{ scrollbarWidth: "none" }}
        >
          {PODCAST_REVIEWS.map((review, index) => (
            <ReviewCard2 review={review} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
}

const ReviewCard2 = ({ review }) => {
  const { name, boldText, texts, time } = review;

  return (
    <div
      className="flex-none bg-white text-xs rounded-2xl pt-4 px-6 md:px-8 ml-6 md:w-1/3 w-4/5 "
      style={{ color: "#405672" }}
    >
      <div className="flex justify-between items-center pb-2">
        <span>
          <img src={FiveStar} alt="" className="transform scale-90" />
        </span>
        <span className="text-sm md:text-base leading-5 font-normal">
          {time}
        </span>
      </div>
      <p className="py-4 font-bold text-base md:text-xl">{name}</p>
      <p className="font-semibold text-sm md:text-base py-2 leading-7">
        {boldText}
      </p>
      {texts.map((text, index) => (
        <p
          className="text-sm md:text-base leading-6 font-normal pb-8"
          key={index}
        >
          {text}
        </p>
      ))}
    </div>
  );
};

export default PodcastReview;
