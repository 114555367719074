/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
// @ts-nocheck
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LouiseYoga from "images/louise-yoga.jpg";
import SwitchIcon from "images/switch.png";
import CrossIcon from "images/cross.png";

import { useAuthState, useAuthDispatch } from "contexts/auth";
import { useUserState } from "contexts/user";
import authenticationApi from "apis/authentication";
import usersApi from "apis/users";
import { resetAuthTokens } from "apis/axios";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Welcome() {
  const { authEmail } = useAuthState();
  const authDispatch = useAuthDispatch();
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const { user } = useUserState();

  const handleLogout = async () => {
    try {
      await authenticationApi.logout();
      authDispatch({ type: "LOGOUT" });
      resetAuthTokens();
      window.location.href = "/";
    } catch (error) {
      console.warn(error);
    }
  };

  const fetchSubscriptionData = async () => {
    try {
      const { data } = await usersApi.show({ id: user.id });
      setSubscriptionInfo({
        ...data?.user?.subscription,
        membershipLabel: data?.user?.membership_label,
      });
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    if (user && user.payment_mode == "stripe") {
      fetchSubscriptionData();
    }
  }, []);

  if (!subscriptionInfo && user && user.payment_mode == "stripe") {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="py-48">
          <Spin indicator={antIcon} />
        </div>
      </div>
    );
  }

  const subscriptionType =
    subscriptionInfo?.membershipLabel ||
    subscriptionInfo?.metadata?.plan?.metadata?.membershipTitle;

  return (
    <div className="bg-gray-100 min-h-full md:min-h-screen w-full font-montserrat">
      <div className="w-full md:w-3/4 mx-auto">
        <div
          className="w-full rounded md:rounded-3xl"
          style={{
            color: "#094043",
          }}
        >
          <div className="flex w-full">
            <div className="w-1/2 hidden md:block">
              <img
                src={LouiseYoga}
                alt="Thank you page image"
                className="w-full rounded-l-3xl object-cover h-full"
              />
            </div>

            <div className="w-full bg-white flex justify-center items-center md:w-1/2 py-7 md:rounded-r-3xl relative min-h-full">
              <div className="w-11/12 md:w-5/6 mx-auto ">
                <p className="pl-4 text-3xl font-bold text-center md:text-left">
                  Welcome
                </p>
                <p className="pb-6 pl-4 text-center md:text-left font-medium ">
                  Manage your subscription
                </p>
                <RenderLogoutSection
                  email={authEmail}
                  handleLogout={handleLogout}
                />
                {user && user.payment_mode == "stripe" && (
                  <RenderSubscriptionActionSection
                    subscriptionType={subscriptionType}
                    subscriptionInfo={subscriptionInfo}
                  />
                )}
                {user && user.payment_mode == "in-app" && (
                  <p className="relative top-8 mb-2 text-center text-xs font-medium">
                    *As you have subscription through Apple in-app. Please
                    manage it via Apple in-app purchases.
                  </p>
                )}
                <div className="mt-2 border-t-1">
                  <ExportDataBtn />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const RenderLogoutSection = ({ email, handleLogout }) => {
  return (
    <>
      <div
        className="w-full py-3 px-6 flex items-center justify-center md:justify-between rounded-3xl"
        style={{
          backgroundColor: `rgba(139, 209, 210, 0.15)`,
          color: "#094043",
        }}
      >
        <p className="font-semibold">{email}</p>
        <div
          className="text-purpleTheme border border-primary  py-3 px-8 rounded-xl font-bold text-center cursor-pointer text-xs hidden md:block"
          onClick={handleLogout}
        >
          Logout
        </div>
      </div>
      {/* ======== Mobile ======== */}
      <div
        className="text-purpleTheme border border-primary w-28 mx-auto px-8 rounded-xl font-bold flex items-center justify-center h-10 mt-4 cursor-pointer text-xs md:hidden "
        onClick={handleLogout}
      >
        Logout
      </div>
    </>
  );
};

const RenderSubscriptionActionSection = ({
  subscriptionType,
  subscriptionInfo,
}) => {
  return (
    <>
      <p className="text-2xl mt-14 my-5 font-bold pl-4 text-center md:text-left">
        My subscription
      </p>
      <div
        className="w-full md:h-20 md:flex items-center justify-between rounded-3xl px-1"
        style={{
          backgroundColor: `rgba(139, 209, 210, 0.15)`,
          color: "#094043",
        }}
      >
        <p className="font-semibold pl-3 py-3 flex items-center justify-center text-center">
          {subscriptionInfo?.membershipLabel ||
            subscriptionInfo?.price?.description}
        </p>

        <div className=" justify-center items-center hidden md:flex">
          {subscriptionInfo?.status !== "canceled" &&
            subscriptionInfo?.price?.amount !== 0 && (
              <Link to="/plan-cancellation" className="no-underline">
                <div className="text-white bg-primary py-4 px-6 rounded-xl font-bold text-center cursor-pointer text-xs flex items-center justify-center">
                  <img
                    src={CrossIcon}
                    alt=""
                    className="h-4 w-4 mr-1 text-primary"
                  />
                  Cancel
                </div>
              </Link>
            )}
        </div>
      </div>
      {/* ========= Mobile ===================*/}
      <div className="flex justify-center items-center md:hidden mt-4">
        {subscriptionInfo?.status !== "canceled" && (
          <Link to="/plan-cancellation" className="no-underline">
            <div className="text-white bg-primary py-4 px-6 rounded-xl font-bold text-center cursor-pointer text-xs flex items-center justify-center">
              <img src={CrossIcon} alt="" className="h-4 w-4 mr-1" />
              Cancel
            </div>
          </Link>
        )}
      </div>
    </>
  );
};

const ExportDataBtn = () => {
  const handleDailyDataExport = async () => await usersApi.exportDailyData();
  const handleWeeklyDataExport = async () => await usersApi.exportWeeklyData();

  return (
    <>
      <div
        className="w-full md:h-20 md:flex items-center justify-between rounded-3xl px-4 my-4"
        style={{
          backgroundColor: `rgba(139, 209, 210, 0.15)`,
          color: "#094043",
        }}
      >
        <div
          className="flex-row text-white border bg-primary  py-3 px-8 rounded-xl font-bold text-center cursor-pointer text-xs hidden md:block"
          onClick={handleDailyDataExport}
        >
          Export Daily Data
        </div>
        <div
          className="text-white border bg-primary  py-3 px-8 rounded-xl font-bold text-center cursor-pointer text-xs hidden md:block"
          onClick={handleWeeklyDataExport}
        >
          Export weekly Data
        </div>
      </div>

      {/* ========= Mobile ===================*/}
      <div
        className="flex items-center justify-between md:hidden mt-12 p:4 border-primary rounded-xl "
        style={{
          backgroundColor: `rgba(139, 209, 210, 0.15)`,
          color: "#094043",
        }}
      >
        <div
          className="flex-row text-white border bg-primary m-1 py-2 px-4 rounded-xl font-bold text-center cursor-pointer text-xs md:block"
          onClick={handleDailyDataExport}
        >
          Export Daily Data
        </div>
        <div
          className="text-white border bg-primary m-1 py-2 px-4 rounded-xl font-bold text-center cursor-pointer text-xs md:block"
          onClick={handleWeeklyDataExport}
        >
          Export weekly Data
        </div>
      </div>
    </>
  );
};

export default Welcome;
