// @ts-nocheck
import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";

import WhiteTick from "images/LandingPageAssets/WhiteTick.svg";

const PLANS_INFO = {
  MONTHLY: [
    "Daily Podcast",
    `Daily ”Radio”`,
    "Weekly nutrition Q&As",
    "Daily in-depth support",
    "Assess to our nutritionist 1-1 via email",
    "Full app feature",
  ],
};

import checkoutApi from "apis/checkout";
import SubscriptionModal from "components/Payment/SubscriptionModal";
import { getStripe } from "utils/initStripe";
import { challengePrices, freeTrialChallenges } from "../constant";

function Challenges() {
  const [showSubModal, setShowSubModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [challengeDetails, setChallengeDeatils] = useState([]);
  const [priceId, setPriceId] = useState(null);
  const [trialEnd, setTrialEnd] = useState(null);

  const proceedToCheckout = async ({
    email,
    priceId,
    password,
    passwordConfirmation,
  }) => {
    try {
      const { data } = await checkoutApi.retrieve({
        email: email.toLowerCase(),
      });
      if (!data.allow_checkout) return;

      const checkoutData = await axios.post(
        "/api/v2/subscriptions/free_trial",
        {
          subscription: {
            email: email.toLowerCase(),
            priceId,
            password,
            passwordConfirmation,
            trialEnd,
          },
        }
      );
      setShowSubModal(false);

      message.success(
        "Congratulations! your 14 Day Nutrition Masterclass Trial has been successful activated."
      );
    } catch (error) {
      console.log("Proceed to checkout err:", error);
    }
  };

  const fetchPrices = async () => {
    try {
      const {
        data: { prices },
      } = await axios.get("/api/v1/prices");
      const subPrices = challengePrices(prices.data);
      setChallengeDeatils(freeTrialChallenges(subPrices));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <div>
        <div className="container relative px-5 pb-20 mx-auto font-montserrat">
          <h2 className="text-3xl font-bold text-center text-black mb-15 md:text-4xl"></h2>
          <div className="text-center">
            <h1 className="font-semibold text-2xl text-gray-800">
              Access to the macros app with full features
            </h1>
          </div>

          <div className="w-full py-10 px-4 mx-auto bg-white rounded-2xl md:w-8/12">
            <div className="flex w-full md:w-11/12 gap-10 mx-auto text-center justify-center">
              {challengeDetails.map(challenge => {
                return (
                  <Card
                    key={challenge.title}
                    challenge={challenge}
                    setPriceId={setPriceId}
                    setShowSubModal={setShowSubModal}
                    setTrialEnd={setTrialEnd}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* <SubscriptionModal
        isOpen={showSubModal}
        onClose={() => setShowSubModal(false)}
        proceedToCheckout={proceedToCheckout}
        stripePriceId={priceId}
      /> */}
    </>
  );
}

const Card = ({ challenge, setPriceId, setShowSubModal, setTrialEnd }) => {
  const { priceId, trialEnd } = challenge;
  const handleCheckout = () => {
    setPriceId(priceId);
    setTrialEnd(trialEnd);
    setShowSubModal(true);
  };

  return (
    <>
      <div
        className="md:mx-4 md:w-3/5 w-full border-2 text-white border-solid px-6 py-12 rounded-2xl bg-purpleTheme mb-8"
        style={{ borderColor: "#685CC1" }}
      >
        <p
          className="text-base md:text-xl leading-8 font-medium pb-2 text-left"
          style={{ color: "#FFCC00" }}
        >
          Trial
        </p>
        <h2 className="font-bold text-3xl md:text-4xl text-left">
          £0{" "}
          <span className="text-base md:text-xl font-semibold">
            {" "}
            (14 Days Free Trial)
          </span>
        </h2>
        <h2 className="text-xs font-semibold text-left pt-4">
          Free Trials With No Credit Card Required
        </h2>
        <div className="pt-8 pb-12">
          {PLANS_INFO.MONTHLY.map((point, index) => (
            <div className="flex pb-2 items-center justify-start" key={index}>
              <div className="w-2/12">
                <span className="h-6 w-6 flex items-center justify-center">
                  <img src={WhiteTick} alt="" className="h-full w-full" />
                </span>
              </div>
              <p className="w-10/12 text-base md:text-base leading-7 font-medium text-left">
                {point}
              </p>
            </div>
          ))}
        </div>
        <button
          className="w-full py-5 text-purpleTheme bg-white font-bold text-base rounded-2xl"
          onClick={handleCheckout}
        >
          Signup Now
        </button>
      </div>
    </>
  );
};

export default Challenges;
