import axios from "axios";

const login = payload => axios.post("api/v1/login", payload);

const logout = () => axios.delete("api/v1/logout");

const signup = payload => axios.post("api/v1/users", payload);

const resetPassword = payload => axios.post("/api/v1/passwords", payload);

const updatePassword = payload => axios.put("/api/v1/passwords", payload);

const authenticationApi = {
  login,
  logout,
  signup,
  resetPassword,
  updatePassword,
};

export default authenticationApi;
