import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "antd";

import CloseEnvolopSVG from "images/Mail.svg";

function sendEmailConfirmation({ isOpen, setIsOpen }) {
  return (
    <Modal open={isOpen} onCancel={() => setIsOpen(false)} className="w-80" footer={null}>
      <div className="text-xl font-bold pb-5 text-center">
        Email has been sent
      </div>
      <p className="text-xs pb-3 leading-5 text-center">
        Please check your inbox and click in the received link to reset a
        password
      </p>

      <div className="w-52 h-52 mx-auto mb-10">
        <img src={CloseEnvolopSVG} alt="Envolop" className="w-full h-full" />
      </div>

      <Link
        to="/login"
        onClick={() => setIsOpen(false)}
        className="text-white py-3 px-12 text-xs rounded-xl font-bold text-center cursor-pointer border-none mx-auto focus:outline-none mx-auto"
        style={{
          backgroundColor: `#19A198`,
        }}
      >
        Login
      </Link>
    </Modal>
  );
}

export default sendEmailConfirmation;
