// @ts-nocheck
import React, { useEffect } from "react";

import LouiseImage from "images/LandingPageAssets/LouiseImage1.png";
import MaskGroup1 from "images/LandingPageAssets/MaskGroup1.png";

import AudioFlame from "images/LandingPageAssets/audio.png";
import FoodFlame from "images/LandingPageAssets/food.png";
import LoveFlame from "images/LandingPageAssets/food.png";
import PodcastFlame from "images/LandingPageAssets/podcast.png";
import NoteFlame from "images/LandingPageAssets/note.png";
import timeFlame from "images/LandingPageAssets/time.png";

import ChatImage1 from "images/LandingPageAssets/chatImage1.png";
import ChatImage2 from "images/LandingPageAssets/ChatImage2.png";
import ChatImage3 from "images/LandingPageAssets/ChatImage3.png";
import CharmTick from "images/LandingPageAssets/charm_tick.png";

import HeroSection from "../Navbar/Header/index";
import ComparisonTable from "./Designs/ComparisonTable";
import ReviewSection from "./Designs/ReviewSection";
import ReviewSection2 from "./Designs/ReviewSection2";
import Team from "./Designs/Team";
import WishList from "./Designs/WishList";
import FAQ from "./Designs/FaqSection";
import ArticleSection from "./Designs/ArticleSection";
import KeyFeatureSection from "./Designs/KeyFeature";
import PodcastReview from "./Designs/PodcastReview";

const PRODUCT_FEATURE = [
  {
    image: FoodFlame,
    text:
      "Revolutionary nutrition & mindset app that’s easy to use even if you’ve never tracked your food before",
  },
  {
    image: AudioFlame,
    text: "Unrivalled daily support by expert, empathetic coaches",
  },
  {
    image: NoteFlame,
    text: "Expert education (backed by unbiased research)",
  },
  {
    image: PodcastFlame,
    text:
      "Daily 15-minute podcasts to help you stay grounded in the one day at a time philosophy whilst also sharing research studies and knowledge. ",
  },
  {
    image: LoveFlame,
    text: "Human accountability that’s light-hearted and fun",
  },
  {
    image: timeFlame,
    text:
      "Turtle radio every morning at 7.30am - tune in to listen to how the community is doing and their top tips. Easily join as a speaker or just listen whilst getting ready for your day  ",
  },
];

const AppLanding = () => {
  useEffect(() => {
    (function (d, u, ac) {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.src = "https://a.omappapi.com/app/js/api.min.js";
      s.async = true;
      s.dataset.user = u;
      s.dataset.account = ac;
      d.getElementsByTagName("head")[0].appendChild(s);
    })(document, 57680, 65019);
  }, []);

  return (
    <>
      <p id="home"></p>
      <HeroSection />
      <RestContent />
      <KeyFeatureSection />
      <p id="comparison"></p>
      <ComparisonTable />
      <ReviewSection />
      <RestContent2 />
      <RestContent3 />
      <PodcastReview />
      <ReviewSection2 />
      <ArticleSection />
      <Team />
      <FAQ />
      <p id="contact_us"></p>
      <WishList />
    </>
  );
};

const RestContent = () => {
  return (
    <div
      className="relative w-full z-30"
      style={{ backgroundColor: "#FBF4F4" }}
    >
      <div className="px-6 w-full md:w-3/4 mx-auto relative flex flex-wrap flex-col-reverse md:flex-row">
        <div className="relative w-full md:w-1/2 flex items-center justify-center flex-col pt-4 ">
          <img
            src={LouiseImage}
            alt=""
            className="relative z-20 transform w-3/4"
          />
          <img
            src={MaskGroup1}
            alt=""
            className="absolute z-10 origin-center w-1/2 md:full"
          />
        </div>
        <div className="py-6 md:pl-10 flex flex-col justify-center md:w-1/2 w-full px-3">
          <h3 className="font-bold md:text-5xl text-3xl leading-tight text-center md:text-left">
            Trusted and Used by{" "}
            <u className="text-purpleTheme">Louise Thompson</u>
          </h3>
          <p
            className="pt-5 leading-7 text-base md:text-lg text-center md:text-left"
            style={{ color: "#405672" }}
          >
            Even doctors, nutritionists and dieticians follow The Turtle Method™
          </p>
        </div>
      </div>
    </div>
  );
};

const RestContent2 = () => {
  return (
    <div className="py-10 md:py-24 mt-5" style={{ backgroundColor: "#FEFEFF" }}>
      <h2
        className="text-center text-4xl md:text-5xl font-bold pb-8 md:px-4 px-6"
        style={{ color: "#172F4F" }}
      >
        The Turtle Method in a <u style={{ color: "#ED7870" }}>Nutshell</u>
      </h2>

      <div className="w-full md:w-4/5 flex items-center flex-wrap mx-auto pt-4">
        {PRODUCT_FEATURE.map((feature, index) => {
          return (
            <div
              key={index}
              className={`md:w-1/3 w-full md:h-64 flex flex-col items-center py-4 justify-start ${
                (index + 1) % 3 != 0 && " border-r-2 border-dotted"
              }`}
            >
              <img src={feature.image} alt="" className="w-18 h-18 " />
              <p
                className="text-center leading-7 text-lg px-4 md:px-8 py-4"
                style={{ color: "#405672" }}
              >
                {feature.text}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const RestContent3 = () => {
  return (
    <div style={{ backgroundColor: "#FEFEFF" }} className="pb-10 md:pb-24">
      <div className="w-full md:w-4/5 flex flex-wrap flex-col-reverse md:flex-row mx-auto px-6">
        <div className="w-full md:w-1/2">
          <img src={ChatImage1} alt="" />
        </div>
        <div className="md:pl-16 w-full md:w-1/2 pb-10">
          <h3 className="font-bold text-3xl md:text-4xl md:pt-12">
            <u className="text-purpleTheme">Lose Fat</u> with Ease by Logging
            Your Food in a Few Minutes a Day
          </h3>
          <h4
            className="text-base leading-6 py-4"
            style={{
              color: "#405672",
            }}
          >
            The app includes a barcode scanner, huge food database, recipe
            creator, quick-add, copy meals, multi-add and more so you can track
            your food in minutes each day making it an easy part of your life.{" "}
          </h4>

          <div className="py-4">
            <div className="flex pb-2">
              <span>
                <img src={CharmTick} alt="" />
              </span>
              <p className="text-base leading-5 pl-3 font-semibold">
                Even for first-time trackers, this app makes it easy.
              </p>
            </div>
            <div className="flex pb-2">
              <span>
                <img src={CharmTick} alt="" />
              </span>
              <p className="text-base leading-5 pl-3 font-semibold">
                Spend 0.3% of your day tracking for 90% of your results.
              </p>
            </div>
            <div className="flex pb-2">
              <span>
                <img src={CharmTick} alt="" />
              </span>
              <p className="text-base leading-5 pl-3 font-semibold">
                Even for first-time trackers, this app makes it easy.
              </p>
            </div>
          </div>

          <a href="#contact_us" className="no-underline ">
            <p className="bg-primary text-white px-20 py-2 rounded-2xl font-medium text-base text-center cursor-pointer float-right">
              Join 14 Day Nutrition Masterclass
            </p>
          </a>
        </div>
      </div>
      {/* ================ */}
      <div className="w-full md:w-4/5 flex flex-wrap flex-col-reverse md:flex-row-reverse mx-auto px-6">
        <div className="w-full md:w-1/2">
          <img src={ChatImage2} alt="" />
        </div>
        <div className="md:pl-16 w-full md:w-1/2 pb-10">
          <h3 className="font-bold text-3xl md:text-4xl md:pt-12">
            Gain <u className="text-purpleTheme"> Nutritional Knowledge </u>from
            an Industry-Leading PHD
          </h3>
          <h4
            className="text-base leading-6 py-8"
            style={{
              color: "#405672",
            }}
          >
            Our head of nutrition PhD Paul Rimmer does weekly Q&As with the
            community. There are too many nutrition myths to count and what
            sounds a lot like “science” on Instagram and Tiktok is nonsense.
            When you join The Turtle Method™ you can forget about what you read
            online. We’ll deliver the studies and information that matters so
            you can get on with living.
          </h4>

          <a href="#contact_us" className="no-underline">
            <p className="bg-primary text-white px-20 py-2 rounded-2xl font-medium text-base text-center cursor-pointer float-left">
              Join 14 Day Nutrition Masterclass
            </p>
          </a>
        </div>
      </div>
      {/* =============== */}
      <div className="w-full md:w-4/5 flex flex-wrap flex-col-reverse md:flex-row mx-auto px-6">
        <div className="w-full md:w-1/2">
          <img src={ChatImage3} alt="" />
        </div>
        <div className="md:pl-16 w-full md:w-1/2 pb-10">
          <h3 className="font-bold text-3xl md:text-4xl md:pt-12">
            Reaching your goals in 7 days isn’t possible, but{" "}
            <u className="text-purpleTheme">we can change your mindset.</u>
          </h3>
          <h4
            className="text-base leading-6 py-8"
            style={{
              color: "#405672",
            }}
          >
            Tiny actions are built by living one day at a time. Freeing the
            mind, getting the right information in at the right time and
            destroying myths that have held you back all happen in 7 days. You
            can change your entire mindset in a few days with The Turtle
            Method™. Don’t believe us? Readbelow and check-out our reviews on
            Trustpilot.
          </h4>

          <a href="#contact_us" className="no-underline ">
            <p className="bg-primary text-white px-20 py-2 rounded-2xl font-medium text-base text-center cursor-pointer float-right">
              Join 14 Day Nutrition Masterclass
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppLanding;
