// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IconSVG from "images/Icon.svg";
import WhiteLogoSvg from "images/LandingPageAssets/whiteLogo.svg";
import VectorGroup from "images/Maskgroup.png";
import HeroIphone from "images/LandingPageAssets/HeroIphone.png";

import Hamburger from "images/LandingPageAssets/hamburger.png";

const NavBar = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return <HeroSection />;
};

const HeroSection = () => {
  return (
    <div className="w-full relative flex flex-col md:flex-row ">
      <div className="md:w-2/3 w-full">
        <div className="relative flex">
          <HeroSectionContent />
        </div>
      </div>
      <div className="md:w-1/3 bg-purpleTheme w-full md:block hidden relative h-auto">
        <div className="relative flex h-full bottom-0">
          <div className="w-full h-full relative flex items-center">
            <img
              src={HeroIphone}
              alt=""
              className="h-full -left-40 -bottom-36 absolute z-10   object-contain"
            />
          </div>
          <img
            src={VectorGroup}
            alt=""
            className="w-11/12 absolute top-0 bottom-0 my-auto z-0"
          />
        </div>
      </div>
      <div className="md:hidden w-full h-auto relative -top-8">
        <div className="relative">
          <div className="absolute h-4/5 w-1/2 bg-primary -bottom-16 right-0 overflow-hidden">
            <img
              src={VectorGroup}
              alt=""
              className="absolute top-12 -right-12 my-auto z-0 transform rotate-90 "
            />
          </div>
          <div className="w-4/5 h-auto relative mx-auto">
            <img
              src={HeroIphone}
              alt=""
              className="h-auto -bottom-24 relative z-10"
            />
          </div>
        </div>
      </div>
      <div className="mx-auto py-5 bg-transparent absolute left-0 right-0">
        <Nav />
      </div>
    </div>
  );
};

const HeroSectionContent = () => {
  return (
    <div className="h-auto px-2 md:px-18 pt-24 md:pt-40 ">
      <h1 className="text-4xl hidden md:block font-bold w-5/6">
        The amazing fat loss app <br />
        <strong className="text-purpleTheme font-bold">
          {" "}
          Used by busy people who{" "}
        </strong>{" "}
        <br /> Don’t want strict diet plans!
      </h1>
      <h1 className="text-2xl md:hidden font-bold text-center leading-snug px-4 pt-6">
        The amazing fat loss app <br />
        <strong className="text-primary font-bold">
          {" "}
          Used by busy people who{" "}
        </strong>{" "}
        <br /> Don’t want strict diet plans!
      </h1>{" "}
      <h2
        className="text-base md:text-lg py-6 md:py-10 leading-7 tracking-wide px-4 md:text-left text-center md:w-3/4 hidden md:block"
        style={{ color: "#405672" }}
      >
        "Great 14 day trial, educational, informative and most importantly,
        applicable to every day life!" - Laura
      </h2>
      <div className="md:hidden">
        <h2
          className="text-sm pt-6 md:py-10 leading-5 tracking-wide px-4 text-center"
          style={{ color: "#405672" }}
        >
          "Great 14 day trial, educational, informative and most importantly,
          applicable to every day life!" -Laura
        </h2>
      </div>
      <div className="flex md:block justify-center items-center mt-4">
        <a
          href="https://app.monstercampaigns.com/c/mxevyzqoedpy99d8jsvr/"
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline"
        >
          <button className="text-base leading-4 font-bold tracking-wider bg-purpleTheme border text-white px-4 py-5 rounded-2xl ">
            Start 14 day free trial
          </button>
        </a>
      </div>
      <p className="text-sm py-2 px-2 font-normal text-center md:text-left">
        New intake starts every Sunday. Enrol for free now.
      </p>
      <div
        className="trustpilot-widget md:py-10 pt-6"
        data-locale="en-GB"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="6241a77ac40663fab1b7b56d"
        data-style-height="140px"
        data-style-width="100%"
        data-theme="light"
        data-tags="masterclass"
        data-stars="5"
        data-review-languages="en"
      >
        <a
          href="https://uk.trustpilot.com/review/turtlemethod.com"
          target="_blank"
          rel="noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </div>
  );
};

export const Nav = () => {
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  return (
    <div className="relative px-6 md:px-18">
      <div className="w-full md:mx-auto flex justify-between items-center bg-transparent relative">
        <Link to="/" className="no-underline">
          <div className="flex justify-center items-center">
            <Logo />
            <h1 className="ml-2 text-xl leading-5 font-bold align-top">
              Turtle Method™
            </h1>
          </div>
        </Link>
        <div className="flex md:w-1/2 justify-between items-center w-auto">
          <a href="#home" className="no-underline">
            <div
              className="text-base leading-4 tracking-wider font-medium cursor-pointer hidden md:block"
              style={{ color: "#172F4F" }}
            >
              Home
            </div>
          </a>
          <a href="#comparison" className="no-underline">
            <div
              className="text-base leading-4 tracking-wider font-medium cursor-pointer hidden md:block"
              style={{ color: "#172F4F" }}
            >
              Comparison
            </div>
          </a>
          <a href="#contact_us">
            <div className="text-base leading-4 tracking-wider font-medium text-white cursor-pointer hidden md:block ">
              Contact Us
            </div>
          </a>
          <Link to="/login" className="no-underline">
            <div className="text-base leading-4 tracking-wider font-medium  border border-white px-5 py-3 rounded-xl text-white cursor-pointer hidden md:block">
              Login
            </div>
          </Link>
          <div
            className="text-base leading-4 tracking-wider font-medium rounded-xl text-black cursor-pointer md:hidden"
            onClick={() => setShowMobileDropdown(true)}
          >
            <img src={Hamburger} alt="" />
          </div>
        </div>
      </div>

      {showMobileDropdown && (
        <div
          className="fixed inset-0 z-50 flex flex-col items-center justify-between w-full h-full p-10 text-center text-white"
          style={{ backgroundColor: "#322239" }}
        >
          <Link to="/" className="no-underline">
            <div className="flex justify-center items-center">
              <WhiteLogo />
              <h1 className="ml-2 text-lg leading-5 font-bold align-top">
                Turtle Method™
              </h1>
            </div>
          </Link>

          <div className="flex flex-col space-y-5 text-xl">
            <a href="#home">
              <span
                className="font-semibold no-underline cursor-pointer"
                onClick={() => setShowMobileDropdown(false)}
              >
                Home
              </span>
            </a>

            <a href="#comparison">
              <span
                className="font-semibold no-underline cursor-pointer"
                onClick={() => setShowMobileDropdown(false)}
              >
                Comparison
              </span>
            </a>

            <a href="#contact_us">
              <span
                onClick={() => {
                  setShowMobileDropdown(false);
                }}
                className="font-semibold no-underline cursor-pointer"
              >
                Contact Us
              </span>
            </a>

            <Link
              to="/login"
              className="font-semibold no-underline cursor-pointer"
              onClick={() => setShowMobileDropdown(false)}
            >
              Login
            </Link>
          </div>

          <div
            className="cursor-pointer"
            onClick={() => setShowMobileDropdown(false)}
          >
            <svg
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="8.76807"
                y="27.8599"
                width="27"
                height="2.2"
                rx="1.1"
                transform="rotate(-45 8.76807 27.8599)"
                fill="white"
              />
              <rect
                x="27.86"
                y="29.4155"
                width="27"
                height="2.2"
                rx="1.1"
                transform="rotate(-135 27.86 29.4155)"
                fill="white"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

const Logo = () => {
  return (
    <div>
      <div className="h-9 w-9 md:h-auto md:w-auto text-black rounded-full flex justify-center items-center">
        <img src={IconSVG} alt="" className="h-full w-full bg-white" />
      </div>
    </div>
  );
};

const WhiteLogo = () => {
  return (
    <div>
      <div className="h-9 w-9 md:h-auto md:w-auto text-black rounded-full flex justify-center items-center">
        <img src={WhiteLogoSvg} alt="" className="h-full w-full" />
      </div>
    </div>
  );
};

export default NavBar;
