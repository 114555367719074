// @ts-nocheck
/* eslint-disable no-console */
import React, { useState } from "react";

import { withRouter } from "react-router";
import { Form, Input, Button } from "antd";

import LouiseBiceps from "images/image-louise-biceps.jpg";
import usersApi from "apis/users";

const INITIALIZE_ERRORS = {
  email: "",
  password: "",
};

const DeleteAccount = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setError] = useState(INITIALIZE_ERRORS);
  const [
    showAccountDeletionSuccessful,
    setShowAccountDeletionSuccessful,
  ] = useState(false);

  const isValid = () => {
    let emailError;
    let passwordError;

    if (!password) {
      passwordError = "Password can't be blank.";
    }

    if (!email) {
      emailError = "Email can't be blank.";
    }

    if (!passwordError && !emailError) {
      return true;
    } else {
      setError({
        ...errors,
        password: passwordError,
        email: emailError,
      });
      return false;
    }
  };

  const handleAccountDeletion = async event => {
    event.preventDefault();
    alert(
      "Once you choose to delete your account, all related data to your account will be permanently deleted and cannot be recovered."
    );
    try {
      if (isValid()) {
        const payload = {
          email: email,
          password: password,
        };
        const res = await usersApi.deleteAccount(payload);
        if (res.status === 200) {
          setShowAccountDeletionSuccessful(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen w-full font-montserrat">
      <div className="w-full md:w-3/4 mx-auto">
        <div
          className="w-full rounded md:rounded-l-3xl"
          style={{
            color: "#094043",
          }}
        >
          <div className="flex w-full">
            <div className="w-1/2 hidden md:block">
              <img
                src={LouiseBiceps}
                alt="Thank you page image"
                className="w-full rounded-l-3xl object-cover h-full"
              />
            </div>

            {!showAccountDeletionSuccessful ? (
              <Form
                className="w-full bg-white flex items-center md:w-1/2 md:rounded-r-3xl"
                onFinish={handleAccountDeletion}
              >
                <div className="w-full py-8 md:px-4 md:py-12">
                  <div className="text-3xl w-4/5 font-bold mx-auto text-center py-8">
                    Delete Account
                  </div>

                  <div className="w-7/12 mx-auto ">
                    <Form.Item
                      label="Email"
                      name="email"
                      required
                      hasFeedback
                      validateStatus={errors.email ? "error" : ""}
                      help={errors.email}
                      labelCol={{ className: "test-xs pb-1 pl-2" }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        placeholder="email"
                        id="email"
                        value={email}
                        onChange={e => {
                          setEmail(e.target.value);
                          setError(INITIALIZE_ERRORS);
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      required
                      hasFeedback
                      validateStatus={errors.password ? "error" : ""}
                      help={errors.password}
                      labelCol={{ className: "test-xs pb-1 pl-2" }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input.Password
                        placeholder="******"
                        value={password}
                        onChange={e => {
                          setPassword(e.target.value);
                          setError(INITIALIZE_ERRORS);
                        }}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="text-white w-full mx-auto pb-3 my-2 rounded-xl font-bold text-center cursor-pointer mb-4 focus:outline-none"
                        style={{ backgroundColor: `#DC3545` }}
                        onClick={handleAccountDeletion}
                        disabled={!email || !password}
                      >
                        Proceed
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            ) : (
              <div className="w-1/2 hidden md:block bg-white rounded md:rounded-r-3xl">
                <div className="text-3xl w-4/5 font-bold mx-auto my-auto text-center py-8 bg-white">
                  Account Deleted Successfully
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(DeleteAccount);
