// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import IconSVG from "images/Icon.svg";

import GroupImage1 from "images/LandingPageAssets/GroupWaitlist1.svg";
import GroupImage2 from "images/LandingPageAssets/GroupWaitlist2.svg";

import FBicon from "images/LandingPageAssets/fbicon.svg";
import MediumIcon from "images/LandingPageAssets/mediumIcon.svg";
import twitterIcon from "images/LandingPageAssets/twitterIcon.svg";
import InstagramIcon from "images/LandingPageAssets/InstagramIcon.svg";

function WishList() {
  return (
    <section
      className="text-white relative"
      style={{ backgroundColor: "#101124" }}
    >
      <img src={GroupImage1} alt="" className="absolute top-0 right-0 w-1/6" />
      <img
        src={GroupImage2}
        alt=""
        className="absolute bottom-0 left-0 w-1/6"
      />

      <h3 className="font-bold py-8 text-3xl md:text-5xl text-center">
        <u style={{ color: "#19B089" }}> Join our next 14 day </u> <br />
        nutrition & mindset masterclass
      </h3>

      <p
        className="text-sm w-2/3 text-center mx-auto pb-4"
        style={{ color: "#A2A1B7" }}
      >
        We don't do free trials like the rest. You don't just get app access,
        you'll follow a 14 day masterclass that helps you understand nutrition
        so we can free you from diet culture and food conflict for good. Get
        started by following the button below
      </p>

      <button className="font-bold text-white text-base flex justify-center rounded-xl text-center px-8 py-4 border border-purpleTheme mx-auto bg-purpleTheme my-10">
        <a
          href="https://app.monstercampaigns.com/c/mxevyzqoedpy99d8jsvr/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-white no-underline"
        >
          Join 14 Day Nutrition Masterclass
        </a>
      </button>

      <div className="w-4/5 mx-auto flex justify-center  md:justify-between flex-wrap md:flex-no-wrap my-4 border-t border-b py-4 items-center border-gray-700">
        <Link to="/" className="no-underline">
          <div className="justify-center items-center hidden md:flex">
            <Logo />
            <h1 className="ml-2 text-lg leading-5 font-bold text-purpleTheme align-top">
              Turtle Method
            </h1>
          </div>
        </Link>
        <div className="flex">
          <a href="#home" className="no-underline">
            <span
              className=" text-sm font-medium pr-3"
              style={{ color: "#A2A1B7" }}
            >
              Home
            </span>
          </a>
          <a href="#comparison" className="no-underline">
            <span
              className=" text-sm font-medium px-3"
              style={{ color: "#A2A1B7" }}
            >
              Comparison
            </span>
          </a>
          <a href="#contact_us">
            <span
              className=" text-sm font-medium pl-3"
              style={{ color: "#A2A1B7" }}
            >
              Contact Us
            </span>
          </a>
        </div>
        <div className="flex">
          <a
            href="https://turtlemethod.com/terms-and-conditions/"
            className="no-underline"
          >
            <span
              className="text-sm font-medium px-3"
              style={{ color: "#A2A1B7" }}
            >
              Term of Use
            </span>
          </a>
          <a
            href="https://turtlemethod.com/privacy-policy-trtle-app/"
            className="no-underline"
          >
            <span
              className="text-sm font-medium pl-3"
              style={{ color: "#A2A1B7" }}
            >
              Privacy Policy
            </span>
          </a>
          <Link to="/delete_account" className="no-underline">
            <span
              className="text-sm font-medium pl-3"
              style={{ color: "#A2A1B7" }}
            >
              Delete Account
            </span>
          </Link>
        </div>
      </div>

      <div className="w-4/5 mx-auto flex py-8 justify-center md:justify-between items-center">
        <div
          className="text-sm w-2/3 md:block hidden"
          style={{ color: "#A2A1B7" }}
        >
          All the information on this website is published in good faith and for
          general information purpose only.
        </div>
        <div className="flex">
          <span className="h-8 w-8 rounded-full border border-gray-400 mx-2">
            <img src={FBicon} alt="" className="w-full h-full rounded-full" />
          </span>
          <span className="h-8 w-8 rounded-full border border-gray-400 mx-2">
            <img
              src={twitterIcon}
              alt=""
              className="w-full h-full rounded-full"
            />
          </span>
          <span className="h-8 w-8 rounded-full border border-gray-400 mx-2">
            <img
              src={MediumIcon}
              alt=""
              className="w-full h-full rounded-full"
            />
          </span>
          <span className="h-8 w-8 rounded-full border border-gray-400 ml-2">
            <a
              href="https://www.instagram.com/turtlemethod/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={InstagramIcon}
                alt=""
                className="w-full h-full rounded-full"
              />
            </a>
          </span>
        </div>
      </div>
    </section>
  );
}

const Logo = () => {
  return (
    <div>
      <div className="h-9 w-9 bg-purpleTheme rounded-full flex justify-center items-center">
        <img src={IconSVG} alt="" className="h-4/6 w-4/6 rounded-full" />
      </div>
    </div>
  );
};

export default WishList;
