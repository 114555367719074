/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
// @ts-nocheck
import React from "react";
import { checkIcon } from "components/Utils/SvgIcons";
import CharmTick from "images/LandingPageAssets/charm_tick.png";
import WhiteTick from "images/LandingPageAssets/WhiteTick.svg";

export const prepareMembershipPlanData = (priceList = []) => {
  const prices = {};
  priceList.map(price => {
    const membershipKey = price?.metadata?.type || price?.metadata?.Type;
    if (membershipKey) {
      prices[membershipKey] = {
        ...MEMBERSHIP_PLAN_ADDITIONAL_DATA[membershipKey],
        price: price.unit_amount / 100,
        priceId: price.id,
        productId: price.product,
        membershipType: price?.metadata?.membershipType,
      };
    }
  });
  return prices;
};

const CardFooter = ({ text, SVGIcon }) => {
  if (!text) return null;
  return (
    <div className="absolute bottom-0 left-0 flex items-center w-full px-3 py-3 text-xs text-left bg-primary bg-opacity-10 rounded-b-2xl">
      {SVGIcon && <div className="mr-3">{checkIcon()}</div>}
      {text}
    </div>
  );
};

const PLANS_INFO = {
  MONTHLY: [
    "Daily Podcast",
    `Daily ”Radio”`,
    "Weekly nutrition Q&As",
    "Daily in-depth support",
    "Assess to our nutritionist 1-1 via email",
    "Full app feature",
  ],
  YEARLY: [
    "Daily Podcast",
    `Daily ”Radio”`,
    "Weekly nutrition Q&As",
    "Daily in-depth support",
    "Assess to our nutritionist 1-1 via email",
    "Full app feature",
    // `12 week "complete transformation" workout plan [value £79]`,
    // "12 week beginners yoga course [value £99]",
  ],
  PREMIUM: [
    "All courses are included",
    "Custom macros nutrition plan with weekly check-ins",
    "Over 9 live classes per week",
    "Expert Q&As on nutrition, mindset, mental health, philosophy...",
    "Daily voice notes to keep you accountable and on-track",
    "Free access to famous challenges 3x per year",
    "7 days a week support",
  ],
};

const MEMBERSHIP_PLAN_ADDITIONAL_DATA = {
  MONTHLY: {
    title: "Turtle Silver",
    type: "SILVER",
    cardHeading: "Monthly",
    cardSubheading: "",
    isPaymentModeMonthly: true,
    buyButtonBackgroundColor: "rgb(255, 255, 255)",
    isProPlan: false,
    Footer: null,
    strikethroughText: null,
    discountPercentage: null,
    keyNotes: PLANS_INFO.MONTHLY,
    keyNotesicon: CharmTick,
    subheadingColor: "#189877",
    cardBackgroundColor: "#fff",
    trustPilotRating: true,
    textColor: "#172F4F",
  },
  YEARLY: {
    title: "Turtle Silver",
    type: "SILVER",
    cardHeading: "Yearly",
    cardSubheading: "(12 months for the price of 10)",
    isPaymentModeMonthly: false,
    buyButtonBackgroundColor: "rgb(25,161,152)",
    isProPlan: false,
    Footer: null,
    strikethroughText: null,
    discountPercentage: null,
    keyNotes: PLANS_INFO.YEARLY,
    keyNotesicon: WhiteTick,
    subheadingColor: "#189877",
    cardBackgroundColor: "#685CC1",
    trustPilotRating: false,
    textColor: "#FFFFFF",
  },
  "50%_SALE_YEARLY": {
    title: "Turtle Silver Annual",
    type: "SILVER",
    cardHeading: "Yearly",
    cardSubheading: "Get 50% discount",
    isPaymentModeMonthly: false,
    buyButtonBackgroundColor: "rgb(25,161,152)",
    isProPlan: false,
    Footer: null,
    strikethroughText: "£119",
    discountPercentage: "50% off",
    keyNotes: PLANS_INFO.YEARLY,
    keyNotesicon: WhiteTick,
    subheadingColor: "#189877",
    cardBackgroundColor: "#685CC1",
    trustPilotRating: false,
    textColor: "#FFFFFF",
  },
  GOLD_MONTHLY: {
    // title: "Turtle Gold",
    type: "GOLD",
    cardHeading: "Monthly",
    cardSubheading: "",
    isPaymentModeMonthly: true,
    buyButtonBackgroundColor: "#",
    isProPlan: false,
    Footer: null,
    strikethroughText: null,
    discountPercentage: null,
    keyNotes: PLANS_INFO.PREMIUM,
    keyNotesicon: CharmTick,
    subheadingColor: "#189877",
    // cardBackgroundColor: "#fca302",
    trustPilotRating: false,
    // textColor: "#fff",
    title: "GOLD",
    subTitle: "MONTHLY",
    price: "£39",
    cardBackgroundColor: "yellow",
    textColor: "white",
  },
  PLATINUM_YEARLY: {
    title: "Turtle Platinum",
    type: "PLATINUM",
    cardHeading: "Yearly",
    cardSubheading: "Get 50% discount",
    isPaymentModeMonthly: false,
    buyButtonBackgroundColor: "rgb(25,161,152)",
    isProPlan: false,
    Footer: null,
    strikethroughText: "£119",
    discountPercentage: "50% off",
    keyNotes: PLANS_INFO.PREMIUM,
    keyNotesicon: WhiteTick,
    subheadingColor: "#FFCC00",
    cardBackgroundColor: "rgb(38,38,38)",
    trustPilotRating: false,
    textColor: "#FFFFFF",
  },

  SILVER_MONTHLY: {
    title: "SILVER",
    subTitle: "MONTHLY",
    price: "£9.99",
    cardBackgroundColor: "stone",
    type: "SILVER",
    cardHeading: "Monthly",
    isPaymentModeMonthly: true,
    Footer: null,
    trustPilotRating: false,
    textColor: "white",
  },
  SILVER_ANNUAL: {
    title: "SILVER",
    subTitle: "YEARLY",
    price: "£59",
    cardBackgroundColor: "stone",
    borderColor: "#685CC1",
    type: "SILVER",
    cardHeading: "Yearly",
    isPaymentModeMonthly: false,
    Footer: null,
    trustPilotRating: false,
    textColor: "white",
  },
  // GOLD_MONTHLY: {
  //   title: "GOLD",
  //   subTitle: "MONTHLY",
  //   price: "£39",
  //   cardBackgroundColor: "yellow",
  //   textColor: "white",
  // },
  GOLD_ANNUAL: {
    title: "GOLD",
    subTitle: "YEARLY",
    price: "£299",
    cardBackgroundColor: "yellow",
    textColor: "white",
    type: "GOLD",
    cardHeading: "Yearly",
    isPaymentModeMonthly: false,
    Footer: null,
    trustPilotRating: false,
  },
  PLATINUM_MONTHLY: {
    title: "PLATINUM",
    subTitle: "MONTHLY",
    price: "£79",
    cardBackgroundColor: "gray",
    textColor: "white",
    type: "PLATINUM",
    cardHeading: "Monthly",
    isPaymentModeMonthly: true,
    Footer: null,
    trustPilotRating: false,
  },
  PLATINUM_ANNUAL: {
    title: "PLATINUM",
    type: "PLATINUM",
    cardHeading: "Yearly",
    isPaymentModeMonthly: false,
    Footer: null,
    trustPilotRating: false,
    subTitle: "YEARLY",
    price: "£699",
    cardBackgroundColor: "gray",
    textColor: "white",
  },
};

export const SHOW_PRICES = ["MONTHLY", "50%_SALE_YEARLY"];

export const SHOW_PREMIUM_PRICES = ["GOLD_MONTHLY", "PLATINUM_YEARLY"];

export const PLAN_SECTION_LIST = [
  {
    title: "Silver",
    plans: ["SILVER_MONTHLY", "SILVER_ANNUAL"],
  },
  {
    title: "Gold",
    plans: ["GOLD_MONTHLY", "GOLD_ANNUAL"],
  },
  {
    title: "Platinum",
    plans: ["PLATINUM_MONTHLY", "PLATINUM_ANNUAL"],
  },
];

export const MEMBERSHIP_PLANS = {
  SILVER_MONTHLY: {
    title: "SILVER",
    subTitle: "MONTHLY",
    price: "£9.99",
    cardBackgroundColor: "stone",
  },
  SILVER_YEARLY: {
    title: "SILVER",
    subTitle: "YEARLY",
    price: "£59",
    cardBackgroundColor: "stone",
    borderColor: "#685CC1",
  },
  GOLD_MONTHLY: {
    title: "GOLD",
    subTitle: "MONTHLY",
    price: "£39",
    cardBackgroundColor: "yellow",
    textColor: "white",
  },
  GOLD_YEARLY: {
    title: "GOLD",
    subTitle: "YEARLY",
    price: "£299",
    cardBackgroundColor: "yellow",
    textColor: "white",
  },
  PLATINUM_MONTHLY: {
    title: "PLATINUM",
    subTitle: "MONTHLY",
    price: "£79",
    cardBackgroundColor: "gray",
    textColor: "white",
  },
  PLATINUM_YEARLY: {
    title: "PLATINUM",
    subTitle: "YEARLY",
    price: "£699",
    cardBackgroundColor: "gray",
    textColor: "white",
  },
};
