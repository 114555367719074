import React, { useState } from "react";
import { Form, Input, Modal } from "antd";

// @ts-ignore
import { isEmailValid } from "utils/validator";

import { useUserState } from "contexts/user";

// Initialize error
const INITIALIZE_ERRORS = {
  email: "",
  password: "",
  passwordConfirmation: "",
};

export default function SubscriptionModal(props) {
  const { user } = useUserState();

  const [email, setEmail] = useState(user?.email || "");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [confirmationModalOpen, setConfirmationModal] = useState(false);
  const [error, setError] = useState(INITIALIZE_ERRORS);

  const {
    isOpen,
    onClose,
    proceedToCheckout,
    selectedPlanTitle,
    stripePriceId: priceId,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const isValid = () => {
    let emailError = "";
    let passwordError = "";
    let passwordConfirmationError = "";

    if (!email.trim()) {
      emailError = "Email can't be blank.";
    }
    if (!isEmailValid(email)) {
      emailError = "Invalid email address.";
    }
    if (!password) {
      passwordError = "Password can't be blank.";
    }
    if (password.length < 6) {
      passwordError = "Password is too short (minimum is 6 character)";
    }
    if (passwordConfirmation && passwordConfirmation !== password) {
      passwordConfirmationError = "Both password must match.";
    }
    if (!passwordConfirmation) {
      passwordConfirmationError = "Confirm password can't be blank.";
    }

    if (!passwordError && !passwordConfirmationError && !emailError) {
      return true;
    } else {
      setError({
        ...error,
        email: emailError,
        password: passwordError,
        passwordConfirmation: passwordConfirmationError,
      });
      return false;
    }
  };
  const handleSubmit = () => {
    if (priceId && isValid()) {
      proceedToCheckout({ email, priceId, password, passwordConfirmation });
    }
  };

  const confirmUserDetails = () => {
    if (priceId && isValid()) {
      if (user?.email) {
        // handleSubmit();
      } else {
        setConfirmationModal(true);
      }
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={handleClose}
        className="w-80 relative"
        footer={null}
      >
        {confirmationModalOpen ? (
          <div className="text-center leading-relaxed">
            <p className="text-sm mt-8">
              You are going to subscribe to{" "}
              <span className="font-semibold">{selectedPlanTitle}</span> plan
              with email <span className="font-bold text-base">{email}</span>
            </p>
            <p className="text-xs pb-3 leading-5 pt-4">
              The following details will be used to create your account once
              payment is successful.
            </p>
            <p className="text-xs pb-3 leading-5 pt-2">
              Please confirm your email address.
            </p>
            <div className="flex justify-center">
              <button
                className="border border-primary py-2 px-12 text-xs rounded-xl font-bold text-center cursor-pointer focus:outline-none mt-3 w-2/5 flex justify-center items-center text-primary mr-3"
                onClick={() => setConfirmationModal(false)}
              >
                Back
              </button>
              <button
                className="text-white py-2 px-12 text-xs rounded-xl font-bold text-center cursor-pointer border-none focus:outline-none mt-3 w-2/5 flex justify-center items-center bg-primary mr-3"
                onClick={() => handleSubmit()}
              >
                Proceed
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="text-xl font-bold pb-6">
              You are subscribing to {selectedPlanTitle} plan
            </div>
            {user?.email ? (
              <p className="text-xs pb-3 leading-5">
                Please enter your password to proceed.
              </p>
            ) : (
              <p className="text-xs pb-3 leading-5">
                The following details will be used to create your account once
                payment is successful.
              </p>
            )}
            <Form.Item
              label="Email:"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                  type: "email",
                },
              ]}
            >
              <Input
                disabled={user?.email ? true : false}
                onChange={e => {
                  setEmail(e.target.value);
                  setError(INITIALIZE_ERRORS);
                }}
                value={email}
                className="w-4/5 text-xs mb-2"
              />
            </Form.Item>
            <Form.Item
              label={user?.email ? "Password" : "Set password"}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                onChange={e => {
                  setPassword(e.target.value);
                  if (user?.email) {
                    setPasswordConfirmation(e.target.value);
                  }
                  setError(INITIALIZE_ERRORS);
                }}
                value={password}
                className="w-4/5 text-xs mb-2"
              />
            </Form.Item>
            {!user?.email && (
              <Form.Item
                label="Password confirmation"
                name="password_confirmation"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  onChange={e => {
                    setPasswordConfirmation(e.target.value);
                    setError(INITIALIZE_ERRORS);
                  }}
                  value={passwordConfirmation}
                  className="w-4/5 text-xs"
                />
              </Form.Item>
            )}

            <button
              className="text-white py-2 px-12 text-xs rounded-xl font-bold text-center cursor-pointer border-none focus:outline-none mt-3 w-2/5 flex justify-center items-center bg-primary"
              type="submit"
              onClick={confirmUserDetails}
            >
              Proceed
            </button>
          </div>
        )}
      </Modal>
    </>
  );
}
