/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
// @ts-nocheck
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useUserState } from "contexts/user";
import { Modal } from "antd";


import TrustBox from "components/AppLanding/Designs/TrustPilot";
import { MEMBERSHIP_PLANS } from "./constant";

const Card = props => {
  const {
    handleCheckout,
    isLoggedIn,
    planKey,
    priceDetails = {},
    setPlanTitle,
    stripeSubscriptionDetails,
    subscribedPlanPriceId,
    subscriptionStatus,
    upgradePlan,
  } = props;

  const {
    Footer,
    cardBackgroundColor,
    cardHeading,
    isPaymentModeMonthly,
    membershipType,
    price,
    priceId,
    productId,
    subTitle,
    textColor,
    title,
    trustPilotRating,
    type,
    borderColor,
    borderWidth,
  } = priceDetails;

  const [isOpen, setModal] = useState(false);
  const [isTrial, setTrailStatus] = useState(false);

  const { user } = useUserState();

  useEffect(() => {
    setTrailStatus(stripeSubscriptionDetails?.plan?.metadata?.Type == "TRIAL");
  }, [stripeSubscriptionDetails]);

  return (
    <div
      className={`mx-4 md:mx-6 md:w-1/3 w-full rounded-2xl mb-8 relative py-6 pb-0 box-content shadow-lg hover:shadow-xl cursor-pointer bg-gradient-to-r from-${cardBackgroundColor}-200 to-${cardBackgroundColor}-500`}
      style={{
        // backgroundColor: cardBackgroundColor,
        color: "black",
        borderColor: borderColor || cardBackgroundColor,
        borderWidth: borderWidth || 0,
      }}
    >
      <div className="flex justify-between px-6">
        <div>
          <div className="font-bold text-2xl">{title}</div>
          <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 my-2 bg-purple-600 text-purple-100 rounded-full">
            {subTitle}
          </div>
        </div>
        <div className="font-bold text-3xl">{`£${price}`}</div>
      </div>

      {/* {trustPilotRating && <TrustBox />} */}

      <div className="flex justify-center mt-3">
        <div className="w-full">
          {!isLoggedIn ||
          !stripeSubscriptionDetails ||
          isTrial ||
          subscriptionStatus == "canceled" ? (
            <button
              className="w-full py-4 border-top rounded-b-2xl border-gray-400 font-bold text-base text-purpleTheme bg-white"
              onClick={() => {
                setPlanTitle(`${title} (${cardHeading})`);
                handleCheckout(priceId);
              }}
            >
              {user?.email ? "Buy now" : " Join now"}
            </button>
          ) : (
            <>
              {subscribedPlanPriceId === priceId ? (
                <button className="w-full py-5 border border-purpleTheme font-bold text-base rounded-2xl text-purpleTheme bg-white">
                  Purchased
                </button>
              ) : (
                <button
                  className="w-full py-5 border border-purpleTheme font-bold text-base rounded-2xl text-purpleTheme bg-white"
                  onClick={() => setModal(true)}
                >
                  Join now
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <UpgradPlanConfirmationModal
        isOpen={isOpen}
        setModal={setModal}
        planTitle={`${title} (${cardHeading})`}
        price={price}
        upgradePlan={upgradePlan}
        priceId={priceId}
      />
    </div>
  );
};

const UpgradPlanConfirmationModal = ({
  isOpen,
  setModal,
  planTitle,
  price,
  upgradePlan,
  priceId,
}) => {
  return (
    <Modal open={isOpen} onCancel={() => setModal(false)} className="w-80" footer={null}>
      <div className="text-xl font-bold pb-5 text-center">
        Switch subscription
      </div>
      <p className="text-xs pb-3 leading-5 text-center">
        You are switching from your current subscription to {planTitle} that
        costs £ {price}
      </p>

      <p className="text-xs pb-3 leading-5 text-center">
        Are you sure you want to continue?
      </p>
      <div className="flex justify-center">
        <button
          className="text-white py-3 px-12 text-xs rounded-xl font-bold text-center cursor-pointer border-none focus:outline-none mx-auto mt-10"
          type="submit"
          onClick={() => upgradePlan(priceId)}
          style={{
            backgroundColor: `#19A198`,
          }}
        >
          Proceed
        </button>
      </div>
    </Modal>
  );
};

export default Card;
