// @ts-nocheck
import React from "react";

import MenstrualCycle from "images/LandingPageAssets/MenstrualCycle.png";
import InsightsTab from "images/LandingPageAssets/InsightsTab.png";
import AutoAdjuster from "images/LandingPageAssets/AutoAdjuster.png";

import YellowFlame from "images/LandingPageAssets/yellowFlame.png";
import BlueFlame from "images/LandingPageAssets/BlueFlame.png";
import RedFlame from "images/LandingPageAssets/RedFlame.png";
import AlcoholeToolImage from "images/LandingPageAssets/alcoholeTool.png";
import DailyDiaryImage from "images/LandingPageAssets/DailyDiaryFeature.png";
import MacrosImage from "images/LandingPageAssets/macrosImages.png";

const KeyFeatureSection = () => {
  const KEY_FEATURE = [
    // {
    //   image: DataGraph,
    //   heading: "Data Comparison Studio",
    //   text: [
    //     "Compare your weight data with stress and sleep to see if there’s a correlation. Look for patterns or let Turtle tell you what they are over time.",
    //   ],
    // },
    {
      image: AutoAdjuster,
      heading: "The Macros Planner",
      text: [
        "You can save macros on your quiet days and give yourself more of an allowance on the days you eat out like weekends.",
        "Auto Adjuster* - Turn on “auto adjuster” and your macros will decrease/increase automatically depending on what you ate the day before. The goal is to hit your targets over the week, so we focus on the weekly average.",
        "So if you eat less than your allowance today we will allocate what you didn’t consume to the remaining days left of your week. Magic!",
        "So by the final day of your week (day 7) you’ll have the exact macros you need to hit to ensure you come within your targets that week. No day is “ruined” and this is a flexible and realistic way to live.",
      ],
    },
    {
      image: MenstrualCycle,
      heading: "Menstrual cycle tracker",
      text: [
        "Track your menstrual cycle because it impacts our decision making on a weekly basis. You’ll learn more about you stress, eating, sleep, emotional health and more by tracking your menstrual cycle inside the app. The more you can optimise your life around your menstrual cycle the better.",
      ],
    },

    {
      image: InsightsTab,
      heading: "Insights Tab",
      text: [
        "Get insights into your progress and behaviour vs past weeks and months. Awareness is key and we provide an in-depth awareness of your health.",
      ],
    },
  ];

  return (
    <div className="py-16 text-white" style={{ backgroundColor: "#172F4F" }}>
      <h3 className="text-center pb-8 font-bold text-4xl md:text-5xl">
        Key <u style={{ color: "#19B089" }}>Features</u> You&apos;ll Love
      </h3>
      <div className="flex flex-wrap justify-center p-4">
        {KEY_FEATURE.map((feature, index) => {
          return (
            <div
              className="w-full md:w-2/5 mx-2 my-4 md:m-4 rounded-2xl border border-solid p-3 flex md:flex-row flex-col  items-start"
              key={index}
              style={{
                backgroundColor: "#203956",
                borderColor: "rgba(255, 255, 255, 0.12)",
              }}
            >
              <span className="p-4 w-7/12 md:w-1/3">
                <img src={feature.image} alt="" className="w-full h-full" />
              </span>
              <div className="flex flex-col items-start justify-center py-4 w-full md:w-2/3">
                <h3 className=" text-xl font-medium pb-2">{feature.heading}</h3>
                {feature.text.map((text, index) => (
                  <p
                    className="text-base leading-7"
                    style={{ color: "#C5C5C5" }}
                    key={index}
                  >
                    {text}
                  </p>
                ))}
              </div>
            </div>
          );
        })}
        <div
          className="w-full md:w-2/5 mx-2 my-4 md:m-4 rounded-2xl border border-solid p-3 flex md:flex-row flex-col  items-start"
          style={{
            backgroundColor: "#203956",
            borderColor: "rgba(255, 255, 255, 0.12)",
          }}
        >
          <div className="pt-4 px-4 md:p-4 w-7/12 md:w-4/12 relative ">
            <div className="relative mx-auto my-auto bottom-0">
              <img
                src={YellowFlame}
                alt=""
                className="absolute origin-center"
              />
              <img
                src={AlcoholeToolImage}
                alt=""
                className="relative -top-4 z-10"
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-center md:pb-4 md:py-4 w-full md:w-2/3">
            <h3 className="text-xl font-medium pb-2">
              Alcohol Conversion Tool
            </h3>
            <p className="text-base leading-7" style={{ color: "#C5C5C5" }}>
              Always wondered how alcohol does impact your calories and/or
              macros intake? Convert your alcohol drinks into their equivelent
              carbs or carbs/fat to see the significance. 1 pint of lager is
              equivalent to 57g of carbs for example!
            </p>
          </div>
        </div>
        {/* ========================= */}
        <div
          className="w-full md:w-2/5 mx-2 my-4 md:m-4 rounded-2xl border border-solid p-3 flex md:flex-row flex-col  items-start"
          style={{
            backgroundColor: "#203956",
            borderColor: "rgba(255, 255, 255, 0.12)",
          }}
        >
          <div className="p-4 md:p-4 w-7/12 md:w-4/12 relative ">
            <div
              className="relative mx-auto my-auto bottom-0 overflow-hidden"
              style={{ height: "175px" }}
            >
              <img src={BlueFlame} alt="" className="relative origin-center " />
              <img src={DailyDiaryImage} alt="" className="absolute -top-4" />
            </div>
          </div>
          <div className="flex flex-col items-start justify-center md:pb-4 md:py-4 w-full md:w-2/3">
            <h3 className="text-xl font-medium pb-2">Daily Diary</h3>
            <p className="text-base leading-7" style={{ color: "#C5C5C5" }}>
              Use this quick and interactive diary feature every morning and
              evening. Plan your day, track your stress, sleep and emotions and
              review every day with ease.
            </p>
          </div>
        </div>
        {/* ==================== */}
        <div
          className="w-full md:w-2/5 mx-2 my-4 md:m-4 rounded-2xl border border-solid p-3 flex md:flex-row flex-col  items-start"
          style={{
            backgroundColor: "#203956",
            borderColor: "rgba(255, 255, 255, 0.12)",
          }}
        >
          <div className="p-4 md:p-4 w-7/12 md:w-4/12 relative ">
            <div
              className="relative mx-auto my-auto bottom-0 overflow-hidden"
              style={{ height: "175px" }}
            >
              <img src={RedFlame} alt="" className="relative origin-center" />
              <img src={MacrosImage} alt="" className="absolute -top-4" />
            </div>
          </div>
          <div className="flex flex-col items-start justify-center md:pb-4 md:py-4 w-full md:w-2/3">
            <h3 className="text-xl font-medium pb-2">
              Track water, fibre, salt, saturated fat and more
            </h3>
            <p className="text-base leading-7" style={{ color: "#C5C5C5" }}>
              Track your micros and water to ensure optimal health
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeatureSection;
