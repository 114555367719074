// @ts-nocheck
/* eslint-disable no-console */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Form, Input, Button } from "antd";

import LouiseBiceps from "images/image-louise-biceps.jpg";

import authenticationApi from "apis/authentication";

const INITIALIZE_ERRORS = {
  password: "",
  passwordConfirmation: "",
};

function ResetPassword(props) {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setError] = useState(INITIALIZE_ERRORS);
  const resetToken = new URLSearchParams(props.location.search).get(
    "reset_password_token"
  );

  const buildPayload = () => {
    return {
      user: {
        password: password,
        password_confirmation: passwordConfirmation,
        reset_password_token: resetToken,
      },
    };
  };

  const isValid = () => {
    let passwordError = "";
    let passwordConfirmationError = "";

    if (!password) {
      passwordError = "Password can't be blank.";
    }
    if (password.length < 6) {
      passwordError = "Password is too short (minimum is 6 character)";
    }
    if (passwordConfirmation && passwordConfirmation !== password) {
      passwordConfirmationError = "Both password must match.";
    }
    if (!passwordConfirmation) {
      passwordConfirmationError = "Confirm password can't be blank.";
    }

    if (!passwordError && !passwordConfirmationError) {
      return true;
    } else {
      setError({
        ...errors,
        password: passwordError,
        passwordConfirmation: passwordConfirmationError,
      });
      return false;
    }
  };

  const handleUpdatePassword = async event => {
    event.preventDefault();
    try {
      if (isValid()) {
        await authenticationApi.updatePassword(buildPayload());
        props.history.push("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen w-full font-montserrat">
      <div className="w-full md:w-3/4 mx-auto">
        <div
          className="w-full rounded md:rounded-l-3xl"
          style={{
            color: "#094043",
          }}
        >
          <div className="flex w-full">
            <div className="w-1/2 hidden md:block">
              <img
                src={LouiseBiceps}
                alt="Thank you page image"
                className="w-full rounded-l-3xl object-cover h-full"
              />
            </div>

            <Form
              className="w-full bg-white flex items-center justify-between md:w-1/2 md:rounded-r-3xl"
              onFinish={handleUpdatePassword}
            >
              <div className="w-full py-8 md:px-4 md:py-12">
                <div className="text-3xl w-4/5 font-bold mx-auto text-center py-8">
      Create new password
                </div>

                <div className="w-7/12 mx-auto ">
                  <Form.Item
                    label="Password"
                    name="password"
                    required
                    hasFeedback
                    validateStatus={errors.password ? "error" : ""}
                    help={errors.password}
                    labelCol={{ className: "test-xs pb-1 pl-2" }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input.Password
                      placeholder="******"
                      id="user_email"
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                        setError(INITIALIZE_ERRORS);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="passwordConfirmation"
                    required
                    hasFeedback
                    validateStatus={errors.passwordConfirmation ? "error" : ""}
                    help={errors.passwordConfirmation}
                    labelCol={{ className: "test-xs pb-1 pl-2" }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input.Password
                      placeholder="******"
                      value={passwordConfirmation}
                      onChange={e => {
                        setPasswordConfirmation(e.target.value);
                        setError(INITIALIZE_ERRORS);
                      }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="text-white w-full mx-auto pb-3 my-2 rounded-xl font-bold text-center cursor-pointer mb-4 focus:outline-none"
                      style={{ backgroundColor: `#19A198` }}
                      onClick={handleUpdatePassword}
                    >
          Reset Password
                    </Button>
                  </Form.Item>

                  <hr className="w-4/5 my-5 mx-auto" />

                  <div
                    className="w-full mx-auto py-3 my-2 rounded-xl font-bold text-center cursor-pointer mb-4"
                    style={{ backgroundColor: `rgba(139, 209, 210, 0.15)`, color: "#094043" }}
                  >
                    <Link to="/login">Sign in</Link>
                  </div>
                </div>
              </div>
            </Form>

          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ResetPassword);
