import React from "react";
import { Dropdown, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { NavLink } from "react-router-dom";
import { useUserState } from "contexts/user";

export default function AccountDropdown({ handleLogout }) {
  const { user } = useUserState();
  // const contact = user
  //   ? { name: `${user.first_name} ${user.last_name}` }
  //   : null;
  const name = user ? `${user.first_name} ${user.last_name}` : null;

  const items = [
    {
      key: '1',
      label: (
        <NavLink
          to="/my/profile"
          className="w-full nui-dropdown--item"
          activeClassName="active"
        >
          <span>My profile</span>
        </NavLink>
      ),
    },
    {
      key: '2',
      label: (
        <NavLink
          to="/my/password/edit"
          className="w-full nui-dropdown--item"
          activeClassName="active"
        >
          <span>Change password</span>
        </NavLink>
      ),
    },
    {
      key: '3',
      label: (
        <div onClick={handleLogout} className="w-full nui-dropdown--item">
              Logout
        </div>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['hover']}>
      <div className="cursor-pointer">
        <Avatar size={32} icon={<UserOutlined />} />
        <span className="font-semibold text-gray-800 pointer-events-none">{name}</span>
      </div>
    </Dropdown>
  );

  // return (
  //   <Dropdown
  //     popoverClassName="pl-6"
  //     position="right-top"
  //     interactionKind="hover"
  //     customTarget={() => (
  //       <Avatar className="cursor-pointer" size={32} contact={contact} />
  //     )}
  //     closeOnSelect
  //   >
  //     <li className="font-semibold text-gray-800 pointer-events-none">
  //       {name}
  //     </li>
  //     <div>
  //       <NavLink
  //         to="/my/profile"
  //         className="w-full nui-dropdown--item"
  //         activeClassName="active"
  //       >
  //         <span>My profile</span>
  //       </NavLink>
  //       <NavLink
  //         to="/my/password/edit"
  //         className="w-full nui-dropdown--item"
  //         activeClassName="active"
  //       >
  //         <span>Change password</span>
  //       </NavLink>
  //       <div onClick={handleLogout} className="w-full nui-dropdown--item">
  //         Logout
  //       </div>
  //     </div>
  //   </Dropdown>
  // );
}
