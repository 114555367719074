import React from "react";
import classnames from "classnames";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import { Button, Tooltip, Dropdown } from 'antd';

import SubMenu from "./SubMenu";

export default function NavItem({
  icon,
  link,
  title,
  subLinks,
  onClick,
  className,
}) {
  const history = useHistory();
  const location = useLocation();
  const isActive = link === location.pathname;

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      history.push(link);
    }
  };

  const NavButton = () => (
    <Button
      type="text"
      icon={icon}
      onClick={handleClick}
      className={classnames(
        [
          "w-12 h-12 rounded-md text-2xl flex items-center justify-center opacity-100",
          className,
        ],
        {
          "text-white bg-purple-500 hover:bg-purple-600 hover:text-white": isActive,
          "text-cool-gray-600  hover:bg-cool-gray-300  hover:text-cool-gray-600 ": !isActive,
        }
      )}
    />
  );

  const items = subLinks.map((subLink, index) => {
    return {
      key: index,
      label: (
        <div key={subLink.title}>
          <NavLink
            to={subLink.link}
            className="w-full nui-dropdown--item"
            activeClassName="active"
            onClick={e => e.target.parentElement.click()}
          >
            <span>{subLink.title}</span>
          </NavLink>
        </div>
      )
    };
  });

  if (subLinks) {
    return (
      <Dropdown
        menu={{ items }}
        // popoverClassName="pl-4"
        placement="topRight"
        trigger={['hover']}
        // customTarget={() => <NavButton />}
        // closeOnSelect
      >
        <li className="font-semibold text-gray-800 pointer-events-none">
          {title}
        </li>
      </Dropdown>
    );
  } else {
    return (
      <div className="mb-6">
        <Tooltip title={title} placement="right">
          <NavButton />
        </Tooltip>
      </div>
    );
  }
}
