/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { loadStripe } from "@stripe/stripe-js";

let stripePromise;
const getStripe = () => {
  // will be set to storage in App.jsx
  const stripePK = localStorage.getItem("STRIPE_PK");
  if (!stripePK) return null;

  console.log("Found Stripe Key:", stripePK);
  if (!stripePromise) {
    stripePromise = loadStripe(stripePK);
  }
  return stripePromise;
};

export { getStripe };
